import Stack from "@mui/material/Stack";
import DefaultCover from "../../../../../assets/images/default_image.png";

function AssetPlaceHolder() {
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      rowGap={1}
      position={"relative"}
    >
      <img
        alt={""}
        src={DefaultCover}
        style={{
          width: 180,
          height: 180,
          objectFit: "cover",
          borderRadius: 4
        }}
      />
    </Stack>
  );
}

export default AssetPlaceHolder
