import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import React, { Fragment, useMemo } from "react";
import { RepoxType } from "../../models/RepoxType";
import { ObjectType } from "../../models/ObjectType";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { useDatastreamFileImage } from "../../hooks/datastream/useDatastreamFileImage";
import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

type DatastreamThumbProps = {
  objectId?: string;
  thumb: boolean;
  thumbnailUrl: string;
  mimeType: string;
  repoxType: string;
  objectType: string;
  tooltip: boolean;
  height?: string;
  typeName?: string;
};

export const DatastreamThumb = ({
  thumb,
  thumbnailUrl,
  mimeType,
  repoxType,
  objectType,
  tooltip,
  height,
  typeName
}: DatastreamThumbProps) => {
  const isDtShortcut = useMemo(() => {
    return (
      repoxType === RepoxType.REPOX_LINK && objectType === ObjectType.DATASTREAM
    );
  }, [repoxType, objectType]);

  const isCont = useMemo(() => {
    return (
      repoxType === RepoxType.REPOX_LINK && objectType === ObjectType.CONTAINER
    );
  }, [repoxType, objectType]);

  const { FileIcon } = useDatastreamFileImage(mimeType ? mimeType : "", typeName);

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        width: "50px",
        height: height ? height : "50px",
        borderRadius: 1,
      }}
    >
      {isCont ? (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Stack
            sx={{
              backgroundColor: "secondary.main",
              borderRadius: 12.5,
              width: "40px",
              height: "40px",
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <FolderOpenIcon fontSize={"medium"} sx={{ opacity: 0.6 }} />
          </Stack>
          <ShortcutOutlinedIcon
            sx={{
              fontSize: 18,
              position: "relative",
              marginTop: -2,
              marginLeft: -3,
              //color: "info.darker",
              borderRadius: 12.5,
              backgroundColor: "background.paper",
              color: (theme) =>
                theme.palette.mode === "dark" ? "common.white" : "common.black",
            }}
          />
        </Stack>
      ) : (
        <Fragment>
          {thumb && (
            <Fragment>
              <Fragment>
                {tooltip ? (
                  <Tooltip
                    title={<img src={thumbnailUrl} alt={" "} />}
                    placement="right"
                    style={{ maxWidth: "400px" }}
                    arrow
                  >
                    <img
                      src={thumbnailUrl}
                      alt={" "}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        borderRadius: "inherit",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <img
                    src={thumbnailUrl}
                    alt={" "}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                      borderRadius: "inherit",
                    }}
                  />
                )}
              </Fragment>
            </Fragment>
          )}
          {!isDtShortcut && !thumb && (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: "secondary.main",
                borderRadius: 1,
              }}
            >
              <FileIcon fontSize={"medium"} sx={{ opacity: 0.6 }} />
            </Stack>
          )}
          {isDtShortcut && (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: "secondary.main",
                borderRadius: 1,
              }}
            >
              <Stack
                sx={{
                  width: "30px",
                  height: "30px",
                  position: "relative",
                  marginTop: 2,
                }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <InsertDriveFileOutlinedIcon
                  fontSize={"medium"}
                  sx={{ opacity: 0.6 }}
                />
              </Stack>
              <ShortcutOutlinedIcon
                sx={{
                  fontSize: 18,
                  position: "relative",
                  marginTop: -0.5,
                  marginLeft: -5,
                  borderRadius: 12.5,
                  backgroundColor: "background.paper",
                  color: (theme) =>
                    theme.palette.mode === "dark"
                      ? "common.white"
                      : "common.black",
                }}
              />
            </Stack>
          )}
        </Fragment>
      )}
    </Stack>
  );
};
