import * as React from "react";
import CoreLayout from "../CoreLayout";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";

type DefaultLayoutProps = {
  children: React.ReactNode;
};

function DefaultLayout({ children }: DefaultLayoutProps) {
  const renderLogo = () => {
    return (
      <Link to={`/portal`}>
        <img
          src={require("../../assets/images/store_logo_white.png")}
          style={{ height: 40, marginRight: "20px" }}
          alt={"favicon"}
        />
      </Link>
    );
  };

  return (
    <CoreLayout
      renderLogo={renderLogo}
      renderNavigation={(open) => <Navigation open={open} />}
      renderNotifications={true}
    >
      {children}
    </CoreLayout>
  );
}

export default DefaultLayout;
