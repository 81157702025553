import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import React from "react";
import ContextActionType from "../../../models/ContextActionType";
import { ContextAction } from "./ContextMenuList";
import DatastreamActionType from "../../../models/DatastreamActionType";
import { DatastreamAction } from "../../../pages/Content/Datastreams/MenuList/DatastreamMenuList";
import {useIAMPermission} from "../../../store/userStore";
import {useTranslation} from "react-i18next";

type MenuListProps = {
  handleAction:
    | ((actionType: ContextActionType, entityID: string) => void)
    | ((actionType: DatastreamActionType, entityID?: string) => void);
  handleClose: () => void;
  actions: ContextAction[] | DatastreamAction[];
  entityID?: string;
  entityName?: string;
};

function ActionList({
  actions,
  handleClose,
  handleAction,
  entityID,
  entityName,
}: MenuListProps) {
  const iam = useIAMPermission();
  const {t} =  useTranslation();
  return (
    <MenuList>
      {actions.map((action, index) => {
        const { Icon, type, name, divider, disabled, textColor, permissions } = action;

        if (permissions && !iam.hasSome(permissions)) return null;

        if (divider) {
          return <Divider key={index} />;
        }
        const nameTranslated = t(name ?? "");
        return (
          <MenuItem
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
              // @ts-ignore
              handleAction(type, entityID);
            }}
            key={index}
          >
            {Boolean(Icon) && (
              <ListItemIcon>
                {/* @ts-ignore */}
                <Icon fontSize={"small"} color={textColor} />
              </ListItemIcon>
            )}
            <ListItemText>
              <Typography variant={"caption"} color={textColor}>
                {nameTranslated?.replace("{ENTITY_NAME}", entityName ?? "")}
              </Typography>
            </ListItemText>
          </MenuItem>
        );
      })}
    </MenuList>
  );
}

export default ActionList;
