import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { Fragment, useMemo } from "react";
import { AddOutlined, RemoveCircleOutline } from "@mui/icons-material";
import { propertyValueFilters } from "../../models/GlobalSearch";
import useContainerSchema from "../../hooks/container/useContainerSchema";
import {
  logicalOperatorOptions,
  operatorOptions,
} from "../../pages/Search/Filters/FilterOption";
import { useArrayOptions } from "../../hooks/common/useArrayOptions";
import Property from "../../models/Property";
import { Option } from "../../hooks/form/useFormElements";

type PropertiesConfigProps = {
  types: Option[];
  propertyFilters: propertyValueFilters[];
  handleChange: (prop: string, array: any) => void;
  customWidth?: string;
  disabled?: boolean;
};

function PropertiesConfig({
  types,
  propertyFilters,
  handleChange,
  customWidth,
  disabled
}: PropertiesConfigProps) {
  const [schema] = useContainerSchema(types.length ? types[0].id : undefined);

  const properties = useMemo(() => {
    if (schema.schemaGroups && schema.schemaGroups.length) {
      let propertiesArray = schema.schemaGroups[0].properties;
      schema.schemaGroups.forEach((group, index) => {
        if (index !== 0)
          propertiesArray = propertiesArray.concat(group.properties);
      });
      return propertiesArray.map((x) => x.property);
    }
    return [];
  }, [schema]);

  const propertyOptions = useArrayOptions<Property>(properties, "uuid", "name");

  const handleAddFilter = () => {
    const array = [...propertyFilters, new propertyValueFilters()];
    handleChange("properties", array);
  };

  const handleRemoveFilterAtIndex = (index: number) => {
    const array = [
      ...propertyFilters.slice(0, index),
      ...propertyFilters.slice(index + 1),
    ];
    handleChange("properties", array);
  };

  const handleEditFilter = (prop: string, index: number) => (e: any) => {
    const value = e.target ? e.target.value : e;
    const array = propertyFilters.map((filter, i) => {
      if (index === i) {
        return {
          ...filter,
          [prop]: value,
        };
      }
      return filter;
    });
    handleChange("properties", array);
  };

  return (
    <Fragment>
      {properties.length > 0 ? (
        <Stack direction={"column"} rowGap={2} alignItems={"flex-start"}>
          {propertyFilters.map((opt, index: number) => (
            <Stack
              direction={"row"}
              columnGap={2}
              alignItems={"center"}
              style={{ width: "100%" }}
            >
              {index !== 0 && (
                <TextField
                  size={"small"}
                  value={opt.logicalOperator}
                  fullWidth
                  select
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={disabled}
                  sx={{
                    width: "80px",
                    "& .MuiInputBase-input": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  SelectProps={{ native: true }}
                  onChange={handleEditFilter("logicalOperator", index)}
                >
                  {logicalOperatorOptions.map((opt: any) => (
                    <option key={opt.id} value={opt.id} disabled={opt.disabled}>
                      {opt.name}
                    </option>
                  ))}
                </TextField>
              )}
              <TextField
                label={"Property"}
                size={"small"}
                value={opt.propertyUuid}
                fullWidth
                select
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={disabled}
                sx={{
                  width: customWidth ? customWidth : "120px",
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                SelectProps={{ native: true }}
                onChange={handleEditFilter("propertyUuid", index)}
              >
                <option value={"ANY"}>ANY</option>
                {propertyOptions.map((opt: any) => (
                  <option key={opt.id} value={opt.id} disabled={opt.disabled}>
                    {opt.name}
                  </option>
                ))}
              </TextField>
              <TextField
                label={"Property Value"}
                size={"small"}
                value={opt.propertyValue}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  autoComplete: "off",
                }}
                disabled={disabled}
                style={{ flex: 1 }}
                SelectProps={{ native: true }}
                onChange={handleEditFilter("propertyValue", index)}
              />
              <TextField
                label={"Operator"}
                size={"small"}
                value={opt.operator}
                fullWidth
                select
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={disabled}
                sx={{
                  width: customWidth ? customWidth : "120px",
                  "& .MuiInputBase-input": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                SelectProps={{ native: true }}
                onChange={handleEditFilter("operator", index)}
              >
                {operatorOptions.map((opt: any) => (
                  <option key={opt.id} value={opt.id} disabled={opt.disabled}>
                    {opt.name}
                  </option>
                ))}
              </TextField>
              <IconButton
                size={"small"}
                color={"error"}
                onClick={() => handleRemoveFilterAtIndex(index)}
                disabled={disabled}
              >
                <RemoveCircleOutline fontSize={"small"} />
              </IconButton>
            </Stack>
          ))}
          {!disabled && <Button
            size={"small"}
            onClick={handleAddFilter}
            startIcon={<AddOutlined />}
          >
            Add Property
          </Button>}
        </Stack>
      ) : null}
    </Fragment>
  );
}

export default PropertiesConfig;
