import {useCallback, useEffect, useState} from "react";
import {fetchData} from "../../utils/utils";

export const useFetch = <T>(url: string | null, initialState: T, trigger?: number): [T, boolean, any | null] => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialState);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!url) return;
    setIsLoading(true);
    fetchData(url)
      .then((data) => {
        setData(data);
        setError(null);
        setIsLoading(false)
      })
      .catch((ex) => {
        setError(ex);
        setData(initialState);
        setIsLoading(false)
      });
    // eslint-disable-next-line
  }, [url, trigger]);

  return [data, isLoading, error];
};


export const useFetchTrigger = (): [number, () => void] => {
  const [trigger, setTrigger] = useState(0)

  const handleTrigger = useCallback(() => {
    setTrigger(p => p + 1)
  }, [])

  return [trigger, handleTrigger]
}
