import React from "react";
import { List } from "@mui/material";
import NavigationItem from "../CoreLayout/NavigationItem";
import { useLocation } from "react-router";
import WebIcon from "@mui/icons-material/Web";

type NavigationProps = {
  open: boolean;
};

function Navigation({ open }: NavigationProps) {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };

  return (
    <List>
      {PortalMenuItems.map((tag, index) => (
        <NavigationItem
          key={index}
          open={open}
          path={tag.path}
          icon={tag.icon}
          name={tag.name}
          isActive={isActive}
        />
      ))}
    </List>
  );
}

export default Navigation;

export const PortalMenuItems = [
  {
    icon: WebIcon,
    name: "Portals",
    path: "portal",
    color: "primary.main",
    description: "",
  },
];

