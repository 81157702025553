import React from "react";
import ProfileActionType from "../../models/ProfileActionType";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { Logout, SettingsOutlined } from "@mui/icons-material";
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import IAMPermission from "../../models/IAMPermission";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
export const actions = [
  {
    type: ProfileActionType.account,
    name: "toolbar.account.myAccount",
    Icon: PermIdentityOutlinedIcon,
    disabled: false,
  },
  {
    name: "toolbar.account.adminConsole",
    type: ProfileActionType.console,
    Icon: DeveloperBoardOutlinedIcon,
    permissions: [IAMPermission.developer_console],
  },
  {
    name: "toolbar.account.releaseNotes",
    type: ProfileActionType.releases,
    Icon: NewReleasesOutlinedIcon,
  },
  {
    name: "toolbar.account.documentation",
    type: ProfileActionType.documentation,
    disabled: false,
    Icon: InfoOutlinedIcon,
  },
  {
    type: ProfileActionType.settings,
    name: "toolbar.account.settings",
    Icon: SettingsOutlined,
    disabled: false,
  },
  {
    type: ProfileActionType.feedback,
    name: "toolbar.account.feedback",
    Icon: FeedbackOutlinedIcon,
    disabled: false,
  },
  {
    divider: true,
  },
  {
    name: "toolbar.account.logOut",
    type: ProfileActionType.logout,
    disabled: false,
    Icon: () => (
      <Logout fontSize="small" sx={{ color: "error.main" }} />
    ),
    textColor: "error",
  },
];
