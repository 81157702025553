import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useState} from "react";
import * as React from "react";
import {Language} from "@mui/icons-material";
import i18n, {resources} from "../../i18n";
import {alpha} from "@mui/material/styles";
import {common} from "@mui/material/colors";

function LanguagePicker() {
  const [anchorEl, setAnchorEl] = useState(undefined);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("I18N_LANGUAGE"))

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleLanguage = (code: string) => {
    handleClose()
    i18n.changeLanguage(code)
    localStorage.setItem("I18N_LANGUAGE", code)
    setSelectedLanguage(code)
  }

  return (
    <div>
      <IconButton
        sx={{
          "&:hover": {
            backgroundColor: alpha(common.white, 0.25),
          },
          borderRadius: "8px",
        }}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Language sx={{ color: "common.white", width: 24 }} />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            marginTop: 8,
          },
        }}
      >
        {
          Object.keys(resources).map((code, index) => {
            // @ts-ignore
            const language = resources[code]
            return (
              <MenuItem key={index} selected={code === selectedLanguage} onClick={() => handleLanguage(code)}>
                <ListItemIcon>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${language.flagCode}.png`}
                  srcSet={`https://flagcdn.com/w40/${language.flagCode}.png 2x`}
                  alt=""
                />
                </ListItemIcon>
                <ListItemText>
                  {language.name}
                </ListItemText>
              </MenuItem>
            )
          })
        }
      </Menu>
    </div>
  );
}

export default LanguagePicker