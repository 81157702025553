import * as React from "react";
import CoreLayout from "../CoreLayout";
import LeftNavBar from "./LeftNavBar";
import { Link, Outlet } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import StoreSearch from "layouts/CoreLayout/Header/StoreSearch";

function DefaultLayout() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const renderLogo = () => {
    return (
      <Link to={`/store/content`}>
        <img
          src={require(matches ? "../../assets/images/store_logo_white_icon.png" : "../../assets/images/store_logo_white.png")}
          style={{ height: 40, marginRight: "20px", minWidth: matches ? 40 : "auto" }}
          alt={"favicon"}
        />
      </Link>
    );
  };

  return (
    <CoreLayout
      renderLogo={renderLogo}
      renderNavigation={(open) => <LeftNavBar open={open} />}
      renderSearch={() => <StoreSearch/>}
      renderNotifications={true}
      showChatGPT
    >
      <Outlet />
    </CoreLayout>
  );
}

export default DefaultLayout;
