import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ListItemButton from "@mui/material/ListItemButton";
import React from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

type SettingsTooltipItemProps = {
  setFlg: React.Dispatch<React.SetStateAction<boolean>>;
  path: string;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  name: string;
  isActive: (path: string) => boolean;
};

function SettingsTooltipItem(props: SettingsTooltipItemProps) {
  const { setFlg, path, onClick, name, isActive } = props;

  const icon = (
    <props.icon
      sx={{ color: isActive(path) ? "primary.main" : "grey.650" }}
      fontSize={"small"}
    />
  );

  return (
    <Link to={`/${path}`} style={{ textDecoration: "none", color: "inherit" }}>
      <Tooltip title={name} placement="right" arrow>
        <ListItemButton
          id={path}
          onClick={(event) => {
            onClick(event);
            setFlg(false);
          }}
        >
          {icon}
        </ListItemButton>
      </Tooltip>
    </Link>
  );
}

export default SettingsTooltipItem;
