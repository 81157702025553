import {BitstreamFile} from "./Datastream";
import {API_URL} from "../utils/config";
import {getTenantId} from "../utils/utils";
import DefaultCover from "../assets/images/default_image.png";

export enum MediaAssetSize {
  SM = "sm",
  MD = "md",
  LG = "ld",
}

export enum MediaAssetType {
  ICON = "ICON",
  ASSET = "ASSET"
}

export class MediaAsset {
  uuid: string;
  label: string;
  altText: string;
  type: MediaAssetType;
  description: string;
  createdAt: Date;
  createdBy: Author;
  updatedAt: Date;
  updatedBy: Author;
  bitstreamFile: BitstreamFile;

  constructor(asset: MediaAsset) {
    this.uuid = asset.uuid
    this.label = asset.label
    this.altText = asset.altText
    this.type = asset.type
    this.description = asset.description
    this.createdAt = asset.createdAt
    this.createdBy = asset.createdBy
    this.updatedAt = asset.updatedAt
    this.updatedBy = asset.updatedBy
    this.bitstreamFile = asset.bitstreamFile
  }

  static getMediaUrl(uuid: string, size?: MediaAssetSize) {
    return `${API_URL}/d/media_assets/${uuid}/view?t=${getTenantId()}&size=${size ?? ""}`
  }

  static getCoverImage(uuid: string | undefined | null) {
    return uuid ? this.getMediaUrl(uuid, MediaAssetSize.MD) : DefaultCover
  }
}

export interface Author {
  uuid: string;
  firstname: string;
  lastname: string;
  username: string;
  email: string;
}
