import { create } from "zustand";
import { createCommonStore, Entity, useEntityList } from "./core";
import { API_URL } from "../utils/config";
import {ShortSchema} from "../models/Schema";

export const useSchemaStore = create<Entity<ShortSchema>>(
  createCommonStore(`${API_URL}/cm/schemas/all`, "uuid")
);

export const useSchemaList = (): [ShortSchema[], boolean] => {
  const store = useSchemaStore();
  return useEntityList(store);
};
