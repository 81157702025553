import { create } from "zustand";

export enum Palette {
  classic,
  dark,
  pink,
  blendo,
  os
}

interface PaletteStore {
  palette: Palette
  selectPalette: (palette: Palette) => void
}
const storedTheme = localStorage.getItem("repox-theme")
const initialState = (storedTheme && parseInt(storedTheme)) || Palette.classic

const usePaletteStore = create<PaletteStore>((set) => ({
  palette: initialState,
  selectPalette: (palette: Palette) => {
    set({ palette })
    localStorage.setItem("repox-theme", palette.toString())
  }
}));


export default usePaletteStore
