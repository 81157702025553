import { styled } from "@mui/styles";
import { emphasize } from "@mui/material";
import Chip from "@mui/material/Chip";
import PublicIcon from "@mui/icons-material/Public";
import { Link } from "react-router-dom";
import * as React from "react";

export type Breadcrumb = {
  href: string;
  name: string;
  published?: boolean;
};

export const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    // @ts-ignore
    theme.palette.mode === "light"
      ? // @ts-ignore
        theme.palette.grey[300]
      : // @ts-ignore
        theme.palette.grey[800];
  return {
    backgroundColor,
    // @ts-ignore
    // height: theme.spacing(3),
    textDecoration: "none",
    // @ts-ignore
    color: theme.palette.text.primary,
    cursor: "pointer",
    // @ts-ignore
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      // @ts-ignore
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

type BreadCrumbProps = {
  breadcrumb: Breadcrumb;
};
const BreadCrumb = ({ breadcrumb }: BreadCrumbProps) => {
  return (
    <Link
      key={breadcrumb.href}
      to={breadcrumb.href}
      style={{ textDecoration: "none" }}
    >
      <StyledBreadcrumb
        avatar={
          breadcrumb.published ? (
            <PublicIcon color={"success"} fontSize={"small"} />
          ) : undefined
        }
        key={breadcrumb.href}
        label={breadcrumb.name}
      />
    </Link>
  );
};

export default BreadCrumb;