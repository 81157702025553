import {
  Container as ContainerMui,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import SectionHeader from "../../components/Common/SectionHeader";
import ContainerCard from "../../components/ContainerCard";
import DatastreamCard from "../../components/DatastreamCard";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { RepoxType } from "../../models/RepoxType";
import Container, {
  ContainerExtended,
  ContainerObjectType,
} from "../../models/Container";
import ShareLink from "../../models/ShareLink";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Datastream } from "../../models/Datastream";
import { API_URL } from "../../utils/config";
import { downloadFile } from "../../utils/utils";
import {StyledBreadcrumb} from "../../components/Common/BreadCrumb";
import useContainerChildren from "../../hooks/container/useContainerChildren";
import useQuery, { updateQueryStringParameter } from "../../hooks/query/useQuery";
import { useLocation, useNavigate } from "react-router-dom";
import PreviewDialog from "../../components/PreviewDialog";

type Breadcrumb = {
  uuid: string;
  name: string;
};

type ContainerViewProps = {
  container: ContainerExtended;
  selectedContainer: ContainerExtended;
  link: ShareLink;
  breadCrumbs: Breadcrumb[];
  handleClickDatastreamCard?: React.MouseEventHandler<HTMLElement>;
  handleClickContainerCard?: React.MouseEventHandler<HTMLElement>;
  setSelectedContainerUUID: Dispatch<SetStateAction<string>>;
};

const ContainerView = ({
  selectedContainer,
  link,
  handleClickDatastreamCard,
  handleClickContainerCard,
  setSelectedContainerUUID,
  breadCrumbs,
}: ContainerViewProps) => {

  const { pathname, search } = useLocation();
  const searchParams = useQuery();
  const navigate = useNavigate();

  const previewUuid = searchParams.get("datastream");
  const isShortcut = Boolean(searchParams.get("isShortcut"));
  const { containerResult, datastreamResult } = useContainerChildren(selectedContainer.uuid, true, link.token)
  /* Container Shortcuts */

  /* all Containers */
  const allContainers = useMemo(() => {
    return containerResult.content.map((item) => {
      return Container.containerToContainerObjectType(item);
    });
  }, [containerResult.content]);

  const datastreamShortcuts = useMemo(() => {
    return datastreamResult.content.filter((x) => x.repoxType !== RepoxType.REPOX_BITSTREAM);
  }, [datastreamResult]);

  const handleDownload = useCallback(
    (uuid: string) => {
      const selected = datastreamResult.content?.find((x) => x.uuid === uuid) ?? new Datastream();

      const url = `${API_URL}/datastreams/${selected.uuid}/download?tk=${link.token}`;
      const filename = selected.bitstreamFile?.filename;
      downloadFile(url, filename);
    },
    [datastreamResult.content, link.token]
  );

  const handlePreview = (item: Datastream) => {
    let newSearch;
    if (item.repoxType === RepoxType.REPOX_LINK) {
      const newSearch1 = updateQueryStringParameter(
        search,
        "datastream",
        item.uuid
      );
      newSearch = updateQueryStringParameter(newSearch1, "isShortcut", "true");
    } else {
      newSearch = updateQueryStringParameter(search, "datastream", item.uuid);
    }
    navigate(`${pathname}${newSearch}`);
  };

  const openPreview = useMemo(() => {
    return !!previewUuid;
  }, [previewUuid]);

  return (
    <ContainerMui maxWidth={"lg"} sx={{ pb: 10 }}>
      <Card sx={{ border: "none", mt: 4, boxShadow: 12 }}>
        <CardContent>
          <Stack direction={"column"} rowGap={1}>

              <Stack
                direction={"row"}
                alignItems={"center"}
                columnGap={1}
              >
                {breadCrumbs &&
                  breadCrumbs.map((breadcrumb) => (
                    <Stack
                      key={breadcrumb.uuid}
                      direction={"row"}
                      alignItems={"center"}
                      columnGap={1}
                      onClick={() => setSelectedContainerUUID(breadcrumb.uuid)}
                    >
                      <StyledBreadcrumb label={`${breadcrumb.name}`} />
                      <div>{"/"}</div>
                    </Stack>
                  ))}
                <StyledBreadcrumb label={`${selectedContainer.label}`} />
              </Stack>

            {link.expiredAt && (
              <Stack flexDirection={"row"} alignItems={"center"} columnGap={1}>
                <PriorityHighIcon color={"primary"} fontSize={"small"} />
                <Typography variant={"caption"} color={"primary"}>
                  {" "}
                  link expires at: {link.expiredAt.split(" ")[0]}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack flexDirection={"column"} rowGap={2} mt={4}>
            {allContainers.length > 0 && (
              <Fragment>
                <SectionHeader header={"Containers"} />

                <Grid container spacing={2}>
                  {allContainers.map(
                    (container: ContainerObjectType, index: number) => (
                      <Grid
                        key={index}
                        item
                        sm={12}
                        xs={12}
                        md={6}
                        lg={4}
                        xl={4}
                      >
                        <ContainerCard
                          key={index}
                          container={container}
                          handleOnDoubleClick={handleClickContainerCard}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Fragment>
            )}

            {datastreamResult.content.length > 0 && (
              <Fragment>
                <SectionHeader header={"Datastreams"} />

                <Grid container spacing={2}>
                  {datastreamResult.content.map((datastream, index) => (
                    <Grid key={index} item sm={12} xs={12} md={6} lg={4} xl={4}>
                      <DatastreamCard
                        key={index}
                        datastream={datastream}
                        objectId={
                          datastream.repoxType === RepoxType.REPOX_BITSTREAM
                            ? undefined
                            : datastream.objectId
                        }
                        onClick={handleClickDatastreamCard}
                        onDoubleClick={datastream.repoxType === RepoxType.REPOX_BITSTREAM ? () => handlePreview(datastream) : undefined}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Fragment>
            )}
          </Stack>
        </CardContent>
      </Card>
      <PreviewDialog
        open={openPreview}
        handleClose={() => {
          navigate(`${pathname}`);
        }}
        handleDownload={() => handleDownload(previewUuid ?? "")}
        previewUuid={previewUuid}
        isShortcut={isShortcut}
        shortcuts={datastreamShortcuts ? datastreamShortcuts : []}
        toNavigate={false}
        token={link.token}
      />
    </ContainerMui>
  );
};

export default ContainerView;
