import { ClickAwayListener, Popper } from "@mui/material";
import GlobalSearchField from "components/GlobalSearch/GlobalSearchField";
import { GlobalSearchPopper } from "components/GlobalSearch/GlobalSearchPopper";
import SettingsPopper from "components/GlobalSearch/SettingsPopper";
import { useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import useQuery from "hooks/query/useQuery";
import Container from "models/Container";
import { FullTextSearchDatastream } from "models/Datastream";
import ReservedUUID from "models/ReservedUUID";
import { postData } from "utils/utils";
import { API_URL } from "utils/config";
import ViewSavedSearchDialog from "pages/Search/ViewSavedSearchDialog/ViewSavedSearchDialog";
import SavedSearchOutlinedIcon from "@mui/icons-material/SavedSearchOutlined";
import IconButton from "@mui/material/IconButton";
import {alpha} from "@mui/material/styles";
import {common} from "@mui/material/colors";


const btnStyles = {
  "&:hover": {
    backgroundColor: alpha(common.white, 0.25),
  },
  borderRadius: "8px",
};

const iconStyles = {
  color: "common.white",
  width: 24,
};

function StoreSearch() {
  const [openViewSavedSearch, setOpenViewSavedSearch] = useState(false);
  const searchParams = useQuery();
  const textQuery = searchParams.get("text") ?? "";
  const [searchResultContainers, setSearchResultContainers] = useState<
    Container[]
  >([]);
  const [searchResultDatastreams, setSearchResultDatastreams] = useState<
    FullTextSearchDatastream[]
  >([]);

  const [value, setValue] = useState<string>(textQuery);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setValue(textQuery);
  }, [textQuery]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSettings(null);
  };
  const open = Boolean(anchorEl);
  const openSettings = Boolean(anchorElSettings);
  const id = open ? "simple-popper" : undefined;
  const idSettings = openSettings ? "simple-popper" : undefined;

  useEffect(() => {
    if (!value) {
      return;
    }

    const payload = {
      location: ReservedUUID.MyContentRoot,
      value: value,
      labelSensitivity: "CASE_INSENSITIVE",
      owner: "ANYONE",
      type: "ALL",
      filetype: [],
      isTrashed: false,
      publishStatus: "ALL",
      propertyValueFilters: [],
      fulltextSearch: false,
    };

    let timeout = setTimeout(() => {
      postData(`${API_URL}/searchEM`, payload)
        .then((data) => {
          setSearchResultContainers(data.containers.content);
          setSearchResultDatastreams(data.datastreams.content);
        })
        .catch((ex) => console.log(ex));
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  const routeChange = (parameters: string) => {
    navigate(`/store/search${parameters}`);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <GlobalSearchField
            value={value}
            setValue={setValue}
            handleClick={handleClick}
            handleClickSettings={handleClickSettings}
            handleClose={handleClose}
            onEnter={routeChange}
          />
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={{ zIndex: 10000 }}
          >
            <GlobalSearchPopper
              value={value}
              containers={searchResultContainers}
              datastreams={searchResultDatastreams}
              handleClose={handleClose}
              onFilterClick={routeChange}
            />
          </Popper>
          <Popper
            id={idSettings}
            open={openSettings}
            anchorEl={anchorElSettings}
            placement="bottom-end"
            sx={{ zIndex: 10000 }}
          >
            <SettingsPopper
              open={openSettings}
              handleClose={() => setAnchorElSettings(null)}
            />
          </Popper>
        </div>
      </ClickAwayListener>
      <IconButton
        onClick={() => setOpenViewSavedSearch(true)}
        sx={{ ...btnStyles, ml: 1 }}
      >
        <SavedSearchOutlinedIcon sx={iconStyles} />
      </IconButton>
      <ViewSavedSearchDialog
        open={openViewSavedSearch}
        handleClose={() => setOpenViewSavedSearch(false)}
      />
    </Fragment>
  );
}

export default StoreSearch