enum IAMPermission {

  // Container permissions
  store_container_publish = "store.container.publish",
  store_container_update = "store.container.update",
  store_container_properties = "store.container.properties",
  store_container_tags = "store.container.tags",
  store_container_create = "store.container.create",
  store_container_remove = "store.container.remove",
  store_container_import = "store.container.import",
  store_container_export = "store.container.export",
  store_container_move = "store.container.move",
  store_container_shortcut = "store.container.shortcut",
  store_container_share = "store.container.share",
  store_container_comment = "store.container.comment",
  store_container_report = "store.container.report",
  store_container_printPreview = "store.container.printPreview",
  store_container_permissions = "store.container.permissions",

  // Datastream permissions
  store_datastream_publish = "store.datastream.publish",
  store_datastream_create = "store.datastream.create",
  store_datastream_update = "store.datastream.update",
  store_datastream_properties = "store.datastream.properties",
  store_datastream_move = "store.datastream.move",
  store_datastream_shortcut = "store.datastream.shortcut",
  store_datastream_download = "store.datastream.download",
  store_datastream_remove = "store.datastream.remove",
  store_datastream_tags = "store.datastream.tags",
  store_datastream_share = "store.datastream.share",
  store_datastream_permissions = "store.datastream.permissions",

  // Trash
  store_trash_delete = "store.trash.delete",
  store_trash_restore = "store.trash.restore",

  // Settings
  store_settings_preferences_view = "store.settings.preferences.view",
  store_settings_preferences_edit = "store.settings.preferences.edit",
  store_settings_groups_view = "store.settings.groups.view",
  store_settings_groups_edit = "store.settings.groups.edit",
  store_settings_users_view = "store.settings.users.view",
  store_settings_users_edit = "store.settings.users.edit",
  store_settings_tags_view = "store.settings.tags.view",
  store_settings_tags_edit = "store.settings.tags.edit",

  // Content Model
  store_settings_contentModel_containerTypes_view = "store.settings.contentModel.containerTypes.view",
  store_settings_contentModel_containerTypes_edit = "store.settings.contentModel.containerTypes.edit",
  store_settings_contentModel_datastreamTypes_view = "store.settings.contentModel.datastreamTypes.view",
  store_settings_contentModel_datastreamTypes_edit = "store.settings.contentModel.datastreamTypes.edit",
  store_settings_contentModel_propertyGroup_view = "store.settings.contentModel.propertyGroups.view",
  store_settings_contentModel_propertyGroup_edit = "store.settings.contentModel.propertyGroups.edit",
  store_settings_contentModel_attributes_view = "store.settings.contentModel.attributes.view",
  store_settings_contentModel_attributes_edit = "store.settings.contentModel.attributes.edit",
  store_settings_contentModel_schemas_view = "store.settings.contentModel.schemas.view",
  store_settings_contentModel_schemas_edit = "store.settings.contentModel.schemas.edit",
  store_settings_contentModel_schemas_import = "store.settings.contentModel.schemas.import",
  store_settings_contentModel_schemas_export = "store.settings.contentModel.schemas.export",
  store_settings_contentModel_namespaces_view = "store.settings.contentModel.namespaces.view",
  store_settings_contentModel_namespaces_edit= "store.settings.contentModel.namespaces.edit",
  store_settings_contentModel_vocabularies_view = "store.settings.contentModel.vocabularies.view",
  store_settings_contentModel_vocabularies_edit = "store.settings.contentModel.vocabularies.edit",
  store_settings_contentModel_vocabularies_export = "store.settings.contentModel.vocabularies.export",
  store_settings_contentModel_vocabularies_import = "store.settings.contentModel.vocabularies.import",
  store_settings_contentModel_mappings_view = "store.settings.contentModel.mappings.view",  // to ADD
  store_settings_contentModel_mappings_edit = "store.settings.contentModel.mappings.edit",  // to ADD

  // Developer console
  developer_console = "developer.console",

  // XR
  xr_application_view = "xr.application.view",
  xr_application_edit = "xr.application.edit",
  xr_application_create = "xr.application.create",
  xr_application_publish = "xr.application.publish",
  xr_application_export = "xr.application.export",
  xr_application_delete = "xr.application.delete",

  // Guide
  gd_guide_edit = "gd.guide.edit",
  gd_guide_view = "gd.guide.view",

  // Survey
  srv_survey_edit = "srv.survey.edit",
  srv_survey_view = "srv.survey.view",

  // Stories
  str_story_edit = "str.story.edit",
  str_story_view = "str.story.view",

  // Portal
  pr_portal_edit = "pr.story.edit",
  pr_portal_view = "pr.story.view",

  // AI
  ai_conversation_view = "ai.conversation.view",
  ai_conversation_edit = "ai.conversation.edit",

  // GEO
  geo_view = "geo.parcel.view",
  geo_edit = "geo.parcel.edit",
}

// @ts-ignore
export const STORY_PERMISSIONS = Object.keys(IAMPermission).filter(key => key.includes("str_")).map(x => IAMPermission[x])
// @ts-ignore
export const SURVEY_PERMISSIONS = Object.keys(IAMPermission).filter(key => key.includes("srv")).map(x => IAMPermission[x])
// @ts-ignore
export const GUIDE_PERMISSIONS = Object.keys(IAMPermission).filter(key => key.includes("gd")).map(x => IAMPermission[x])
// @ts-ignore
export const STORE_PERMISSIONS = Object.keys(IAMPermission).filter(key => key.includes("store")).map(x => IAMPermission[x])
// @ts-ignore
export const XR_PERMISSIONS = Object.keys(IAMPermission).filter(key => key.includes("xr")).map(x => IAMPermission[x])
// @ts-ignore
export const PORTAL_PERMISSIONS = Object.keys(IAMPermission).filter(key => key.includes("pr_")).map(x => IAMPermission[x])
// @ts-ignore
export const GEO_PERMISSIONS = Object.keys(IAMPermission).filter(key => key.includes("geo")).map(x => IAMPermission[x])

export default IAMPermission