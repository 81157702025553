import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Checkbox from "@mui/material/Checkbox";
import Fab from "@mui/material/Fab";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

type Props = {
  color: string;
  paperColor: string;
  activeColor: string;
  name: string;
  active?: boolean;
  onCheck?: () => void
  portrait?: boolean
};

function ThemeCard(props: Props) {
  const { activeColor, paperColor, name, color, active, onCheck, portrait } = props;

  return (
    <Card style={{ position: "relative" }}>
      <CardActionArea onClick={onCheck}>
        <Stack direction={"row"}>
          <Stack direction={"column"} style={{ flex: 1 }}>
            <Stack sx={{ backgroundColor: color, flex: 1, p: 2 }} />
            <Stack style={{ height: portrait ? 220 : 160, backgroundColor: paperColor }}>
              <Card sx={{ backgroundColor: paperColor, m: 2 }}>
                <CardContent>
                  <Stack direction={"column"}>
                    <Skeleton
                      sx={{ bgcolor: activeColor }}
                      variant={"text"}
                    ></Skeleton>
                    <Skeleton sx={{ bgcolor: activeColor }}></Skeleton>
                  </Stack>
                </CardContent>
              </Card>
              <Fab
                size={"small"}
                sx={{
                  backgroundColor: color,
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  margin: 1,
                  "&:hover": {
                    backgroundColor: color,
                  },
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </CardActionArea>
      <Divider />
      {onCheck && <CardActionArea>
        <CardActions>
          <Checkbox checked={active} />
          <Typography>{name}</Typography>
        </CardActions>
      </CardActionArea>}
    </Card>
  );
}

export default ThemeCard;
