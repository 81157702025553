import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { SetStateAction, useCallback, useMemo } from "react";
import * as React from "react";
import FormLabel from "@mui/material/FormLabel";
import PlacesAutocomplete from "../../../../components/Common/PlacesAutocomplete";
import { GoogleMap, Marker } from "@react-google-maps/api";
import FormControl from "@mui/material/FormControl";

type Props = {
  values: any;
  setValues: (e: SetStateAction<any>) => void;
  readonly: boolean;
};

function SpatialDefaultValue(props: Props) {
  const { values, setValues, readonly } = props;

  const lng = useMemo(()=>{
    return values?.defaultValue?.longitude ?? 0.0
  }, [values])

  const lat = useMemo(()=>{
    return values?.defaultValue?.latitude ?? 0.0
  }, [values])

  const setNewValue = useCallback(
    (pos: google.maps.LatLng) => {
      setValues((prevState: any) => ({
        ...prevState,
        defaultValue: {
          placeName: values?.defaultValue?.placeName ?? "",
          latitude: pos.lat(),
          longitude: pos.lng(),
        },
      }));
    },
    [setValues, values]
  );

  const setLatitude = (latitude: number) => {
    const pos = new google.maps.LatLng(latitude, lng);
    setNewValue(pos);
  };

  const setLongitude = (longitude: number) => {
    const pos = new google.maps.LatLng(lat, longitude);
    setNewValue(pos);
  };

  const handlePlaceSelection = (place: google.maps.places.PlaceResult) => {
    setValues((prevState: any) => ({
      ...prevState,
      defaultValue: {
        placeName: place.formatted_address ?? "",
        latitude: place.geometry?.location?.lat() ?? 0,
        longitude: place.geometry?.location?.lng() ?? 0,
      },
    }));
  };

  const handleMapClickOrDragEnd = (e: google.maps.MapMouseEvent) => {
    setValues((prevState: any) => ({
      ...prevState,
      defaultValue: {
        placeName: "",
        longitude: e.latLng?.lng() ?? 0,
        latitude: e.latLng?.lat() ?? 0,
      },
    }));
  };

  if (readonly) {
    return (
      <GoogleMap
        mapContainerStyle={{ width: "auto", height: "400px" }}
        center={{ lat: lat, lng: lng }}
        zoom={5}
      >
        <Marker position={{ lat: lat, lng: lng }} />
      </GoogleMap>
    );
  }

  return (
    <FormControl fullWidth>
      <FormLabel sx={{ mb: 1 }}>Default Value</FormLabel>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <PlacesAutocomplete onPlaceSelected={handlePlaceSelection} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <TextField
            variant={"standard"}
            type={"number"}
            fullWidth
            size={"small"}
            value={lat}
            onChange={(e) => setLatitude(Number(e.target.value))}
            label={"Latitude"}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <TextField
            variant={"standard"}
            type={"number"}
            fullWidth
            size={"small"}
            value={lng}
            onChange={(e) => setLongitude(Number(e.target.value))}
            label={"Longitude"}
          />
        </Grid>
        <Grid item md={12} sm={12} xl={12}>
          <GoogleMap
            mapContainerStyle={{ width: "auto", height: "400px" }}
            center={{ lat: lat, lng: lng }}
            zoom={5}
            onClick={handleMapClickOrDragEnd}
          >
            <Marker
              position={{ lat: lat, lng: lng }}
              draggable={true}
              onDragEnd={handleMapClickOrDragEnd}
            />
          </GoogleMap>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default SpatialDefaultValue;
