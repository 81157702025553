import * as React from "react";
import CoreLayout from "../CoreLayout";
import { Link } from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import GeoSearch from "layouts/CoreLayout/Header/GeoSearch";

type DefaultLayoutProps = {
  children: React.ReactNode;
};

function GeoLayout({ children }: DefaultLayoutProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const renderLogo = () => {
    return (
      <Link to={`/geo`}>
        <img
          src={require(matches ? "../../assets/images/store_logo_white_icon.png" : "../../assets/images/store_logo_white.png")}
          style={{ height: 40, marginRight: "20px" }}
          alt={"favicon"}
        />
      </Link>
    );
  };

  return (
    <CoreLayout
      renderLogo={renderLogo}
      renderNotifications={true}
      renderSearch={() => <GeoSearch/>}
      fullWidth={true}
    >
      {children}
    </CoreLayout>
  );
}

export default GeoLayout;
