import {useMemo} from "react";

const useFilterArray = <T>(array: T[], query: string, filterPropsArray: string[]): T[] => {
  return useMemo(() => {
    try {
      return array.filter((x) => {
        // @ts-ignore
        const arrayOfProps = filterPropsArray.map((prop) => x[prop]);
        return arrayOfProps.find((value) =>
          value.toLowerCase().includes(query.toLowerCase())
        );
      });
    } catch (e) {
      return array;
    }
  }, [array, filterPropsArray, query]);
};

export default useFilterArray
