import {useMemo} from "react";

export const useArrayOptions = <T>(array: T[], id: string, name: string, path?:string) => {
  return useMemo(() => {
    if (path){
      // @ts-ignore
      return array.map(item => ({ id: item[id], name: item[name], path:`${path}${item[id]}`}))
    }
    // @ts-ignore
    return array.map(item => ({ id: item[id], name: item[name]}))
  }, [array, id, name, path])
}
