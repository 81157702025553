import { useCallback, useState } from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { propertyValueFilters } from "../../models/GlobalSearch";
import { updateQueryStringParameter } from "../query/useQuery";
import { buildParameters } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import {Option} from "../form/useFormElements";

type OptionType = {
  icon: OverridableComponent<SvgIconTypeMap>;
  title: string;
  keyValue: string;
  query: string;
  color?: string;
};

export type FilterTypes = {
  type: OptionType | null;
  filetype: OptionType[];
  datastreamTypes: Option[];
  containerTypes: Option[];
  location: OptionType | null;
  owner: OptionType | null;
  publishStatus: OptionType | null;
  text: string;
  properties: propertyValueFilters[];
  caseSensitive: boolean;
  fullText: boolean;
  isTrashed: boolean;
};

export const useFilters = (
  values: FilterTypes
): [
  filters: FilterTypes,
  handleChange: (prop: string, url?: boolean) => (e: any) => void,
  updateInitial: (values: any) => void,
  handleChangeArray: (prop: string, array: any) => void
] => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const [filters, setFilters] = useState(values);

  const handleChangeAction = (prop: string, value: any) => {
    switch (prop) {
      case "location":
      case "filetype":
      case "publishStatus":
      case "owner":
      case "text":
        if (value) {
          const newSearch = updateQueryStringParameter(search, prop, value);
          const newSearch1 = updateQueryStringParameter(newSearch, "page", "0");
          return navigate(`/store/search${newSearch1}`);
        } else {
          const newSearch = updateQueryStringParameter(search, prop, "");
          return navigate(`/store/search${newSearch}`);
        }
      case "type":
        if (value) {
          const parameters = buildParameters({
            text: filters?.text,
            location: filters?.location?.query,
            type: value,
            publishStatus: filters?.publishStatus?.query,
            owner: filters?.owner?.query,
            isTrashed: filters.isTrashed ? filters.isTrashed : null,
          });
          return navigate(`/store/search${parameters}`);
        } else {
          const newSearch = updateQueryStringParameter(search, prop, "");
          return navigate(`/store/search${newSearch}`);
        }
      case "datastreamTypes":
      case "containerTypes":
        if (value) {
          const newSearch = updateQueryStringParameter(search, prop, value);
          const newSearch1 = updateQueryStringParameter(
            newSearch,
            "properties",
            ""
          );
          const newSearch2 = updateQueryStringParameter(
            newSearch1,
            "page",
            "0"
          );
          return navigate(`/store/search${newSearch2}`);
        } else {
          const newSearch = updateQueryStringParameter(search, prop, "");
          return navigate(`/store/search${newSearch}`);
        }
      case "isTrashed":
      case "caseSensitive":
        let newSearch;
        if (value) {
          newSearch = updateQueryStringParameter(search, prop, "true");
        } else {
          newSearch = updateQueryStringParameter(search, prop, "");
        }
        return navigate(`/store/search${newSearch}`);
      case "fullText":
        let parameters;
        if (value) {
          parameters = buildParameters({
            text: filters.text,
            location: null,
            type: "DATASTREAM",
            publishStatus: "ALL",
            owner: filters?.owner?.query,
            fullText: true,
          });
        } else {
          parameters = buildParameters({
            text: filters.text,
            location: filters?.location?.query,
            type: filters?.type?.query,
            publishStatus: filters?.publishStatus?.query,
            owner: filters?.owner?.query,
            fullText: null,
          });
        }
        return navigate(`/store/search${parameters}`);
      case "properties":
        const query = updateQueryStringParameter(
          search,
          prop,
          value
        );
        return navigate(`/store/search${query}`);
      default:
        return;
    }
  };

  const handleChange = (prop: string, url?: boolean) => (e: any) => {
    const value = e && e.target ? e.target.value : e ? e.value || e : null;
    const safeValue =
      e && e.target && e.target.type === "checkbox" ? e.target.checked : value;
    if (url) {
      handleChangeAction(prop, safeValue);
    } else {
      setFilters({
        ...filters,
        [prop]: safeValue,
      });
    }
  };

  const handleChangeArray = (prop: string, array: any) => {
    setFilters({
      ...filters,
      [prop]: array,
    });
  };

  const updateInitial = useCallback((values: any) => {
    setFilters(values);
  }, []);

  return [filters, handleChange, updateInitial, handleChangeArray];
};
