import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import LayersIcon from "@mui/icons-material/Layers";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import EventIcon from '@mui/icons-material/Event';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import KeyIcon from '@mui/icons-material/Key';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import IAMPermission from "../../../models/IAMPermission";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import AccountTreeIcon from '@mui/icons-material/AccountTree';

export const defaultMenu = [
  {
    icon: HomeOutlinedIcon,
    name: "sidenav.myContent",
    path: "store/content",
  },
  {
    icon: AccessTimeOutlinedIcon,
    name: "sidenav.recent",
    path: "store/recent",
  },
  {
    icon: StarOutlineOutlinedIcon,
    name: "sidenav.popular",
    path: "store/popular",
  },
  {
    icon: PieChartOutlinedIcon,
    name: "sidenav.reports",
    path: "store/reports",
  },
  {
    icon: DeleteOutlineOutlinedIcon,
    name: "sidenav.trash",
    path: "store/trash",
  },
];

export const SettingsMenu = [
  {
    name: "sidenav.settings.preferences",
    path: "store/settings/preferences",
    icon: TuneIcon,
    color: "primary.main",
    description: "General, System, Storage and more",
    permissions: [IAMPermission.store_settings_preferences_view]
  },
  {
    name: "sidenav.settings.cm",
    path: "store/settings/content-model",
    icon: LayersIcon,
    color: "#1F8B24",
    description: "Content configuration",
    permissions: [
      IAMPermission.store_settings_contentModel_containerTypes_view,
      IAMPermission.store_settings_contentModel_datastreamTypes_view,
    ]
  },
  {
    name: "sidenav.settings.ms",
    path: "store/settings/metadata-schemas",
    icon: SchemaOutlinedIcon,
    color: "#FF6C40",
    description: "Schema configuration",
    permissions: [
      IAMPermission.store_settings_contentModel_schemas_view,
      IAMPermission.store_settings_contentModel_vocabularies_view,
      IAMPermission.store_settings_contentModel_attributes_view,
      IAMPermission.store_settings_contentModel_propertyGroup_view,
      IAMPermission.store_settings_contentModel_namespaces_view,
      IAMPermission.store_settings_contentModel_mappings_view,
    ]
  },
  {
    name: "sidenav.settings.userManagement",
    path: "store/settings/user-management",
    icon: ManageAccountsOutlinedIcon,
    color: "#b82e8a",
    description: "Users, Groups and Permissions",
    permissions: [IAMPermission.store_settings_groups_view, IAMPermission.store_settings_users_view]
  },
  {
    name: "sidenav.settings.tags",
    path: "store/settings/tags",
    icon: LocalOfferOutlinedIcon,
    color: "#DA100B",
    description: "Container and Datastream tags",
    permissions: [IAMPermission.store_settings_tags_view]
  },
];


export const AdminDashboardMenu = [
  {
    name: "admin.console.settings.apiKeys",
    path: "store/admin-console/api-keys",
    icon: KeyIcon,
    color: "primary.main",
    description: "admin.console.settings.apiKeys.subtitle",
    permissions: [IAMPermission.developer_console]
  },
  {
    name: "admin.console.settings.events",
    path: "store/admin-console/events",
    icon: EventIcon,
    color: "#1F8B24",
    description: "admin.console.settings.events.subtitle",
    permissions: [IAMPermission.developer_console]
  },
  {
    name: "admin.console.settings.feedback",
    path: "store/admin-console/feedback",
    icon: FeedbackOutlinedIcon,
    color: "#DA100B",
    description: "admin.console.settings.feedback.subtitle",
    permissions: [IAMPermission.developer_console]
  },
  {
    name: "admin.console.settings.jobs",
    path: "store/admin-console/jobs",
    icon: AccessTimeIcon,
    color: "#b82e8a",
    description: "admin.console.settings.jobs",
    permissions: [IAMPermission.developer_console]
  },
  {
    name: "admin.console.settings.oai-pmh",
    path: "store/admin-console/oai-pmh",
    icon: AccessTimeIcon,
    color: "#FF6C40",
    description: "admin.console.settings.oai-pmh",
    permissions: [IAMPermission.developer_console]
  },
  {
    name: "admin.console.settings.workflows",
    path: "store/admin-console/workflows",
    icon: AccountTreeIcon,
    color: "#2196f3 ",
    description: "admin.console.settings.workflows",
    permissions: [IAMPermission.developer_console]
  }
];
