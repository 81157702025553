import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { ChangeEvent, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type PasswordFieldProps = {
  required?: boolean;
  id: string;
  disabled?: boolean;
  label: string;
  variant: "outlined" | "filled" | "standard";
  value: string;
  onChange: ((e: ChangeEvent<Element>) => void) | undefined;
  helperText?: string;
  error?: boolean;
};

function PasswordField(props: PasswordFieldProps) {
  const [show, setShow] = useState(false);

  const {
    required,
    id,
    label,
    disabled,
    variant,
    value,
    onChange,
    helperText,
    error,
  } = props;

  return (
    <TextField
      fullWidth
      required={required}
      id={id}
      name={id}
      label={label}
      disabled={disabled}
      type={show ? "text" : "password"}
      size={"small"}
      variant={variant}
      value={value}
     // autoComplete={"off"}
      onChange={onChange}
      helperText={helperText}
      error={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShow(!show)} sx={{ mr: -1 }}>
              {!show ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        autoComplete: "new-password",
      }}
    />
  );
}

export default PasswordField;