import TagsType from "./TagsType";

export class InstanceAttribute {
  uuid: string = "";
  attributeUuid: string = "";
  value: any = undefined
}
export class InstanceProperty {
  uuid: string = "";
  propertyUuid: string = "";
  value: any = undefined
  attributes: InstanceAttribute[] = []
}

export class PropertyHolder {
  properties: InstanceProperty[] = []
}

export type ParentContainer = {
  uuid: string,
  label: string
}

class coverFile {
  uuid: string = "";
  viewUrl: string = "";
  filename: string = "";
  filesize: number = 0;
  mimeType: string = "";
  createdAt: string = "";
  thumbnails : Thumbnails = new Thumbnails();
}

class Thumbnails {
  largeUrl: string = "";
  mediumUrl: string = "";
  smallUrl: string = "";
}

class Container extends PropertyHolder {
  uuid: string = "";
  label: string = "";
  type: Type = {
    uuid: "",
    name: ""
  };
  parent: string = "";
  color: string = "";
  createdAt: string = "";
  createdBy: CreatedBy = {};
  updatedAt: string = "";
  updatedBy: UpdatedBy = {};
  publishedAt: string = "";
  publishedBy: PublishedBy = {};
  deletedAt: string = "";
  deletedBy: DeletedBy = {};
  coverFile = new coverFile();
  shared: boolean = false;

  static containerToContainerObjectType(item: Container): ContainerObjectType {
    return {
      uuid: item.uuid,
      label: item.label,
      type: item.type.name,
      color: item.color,
      publishedAt: item.publishedAt,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      coverFile: item.coverFile,
      shared: item.shared,
    }
  }
}

export class ContainerNew {
  label: string = "";
  type: string = "";
  color: string = "#ffffff";
  properties: any
}

class Path {
  uuid: string = "";
  label: string = "";
  color: string = "";
  publishedAt: string = "";
  shared: boolean = false;
}

type Type = {
  uuid: string;
  name: string;
};

type CreatedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

type UpdatedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

type PublishedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

type DeletedBy = {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  username?: string;
  email?: string;
};

class WatchDetails {
  watched: boolean = false;
  watchCount: number = 0;
}

export class ContainerExtended extends Container {
  // childContainers: Container[] = [];
  ancestorPath: Path[] = [];
  // datastreams: Datastream[] = [];
  tags: TagsType[] = [];
  watchDetails = new WatchDetails();

}

export class ContainerObjectType {
  uuid: string = "";
  label: string = "";
  type:string = "";
  color: string = "";
  publishedAt: string = "";
  createdAt: string = "";
  updatedAt: string = "";
  coverFile = new coverFile();
  shared: boolean = false;
}

export class PopularItem {
  uuid: string = "";
  label: string = "";
  viewCount: number = 0;
  parent: string = "";
}

export default Container
