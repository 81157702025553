import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import ChangePassword from "../../assets/images/undraw_my_password_re_ydq7(1).svg";
import { postData } from "../../utils/utils";
import {API_URL} from "../../utils/config";
import {useNavigate} from "react-router-dom";
import useQuery from "../../hooks/query/useQuery";

const validationSchema = yup.object({
  password: yup
    .string()
    .min(4, "Password should be of minimum 4 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .min(4, "Password should be of minimum 4 characters length")
    .required("Password is required"),
});

const ResetPassword = () => {

  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const searchParams = useQuery();
  const token = searchParams.get("tk");

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (values.password !== values.confirmPassword) {
        enqueueSnackbar(
          "New Password and Confirm Password do not match. Please, reenter the fields!",
          {
            variant: "error",
          }
        );
        return;
      }
      const payload = {
        token: token,
        password: values.password,
      }
      try {
        await postData(API_URL + `/auth/reset_password`, payload);
        enqueueSnackbar("You have successfully reset your password! ", {
          variant: "success",
        });

        setTimeout(() => {
          navigate("/login");
        }, 1000);

      } catch (ex) {
        enqueueSnackbar(
          "Something went wrong. Try again!",
          { variant: "error" }
        );
      }
    },
  });

  return (
    <Box>
      <img
        src={require("../../assets/images/store-transparent.png")}
        style={{
          position: "absolute",
          width: "50px",
          marginTop: 40,
          marginLeft: 40,
        }}
        alt={"Logo"}
      />
      <Stack
        flexDirection={"column"}
        gap={1}
        alignItems={"center"}
        pt={8}
      >
        <Typography variant={"h6"} color={"primary.main"}>
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit} style={{paddingBottom:40}}>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant={"standard"}
            id="password"
            name="password"
            label="New Password"
            value={values.password}
            onChange={handleChange}
            error={touched.password && Boolean(errors.password)}
            helperText={
              touched.password &&
              errors.password && (
                <Typography
                  component={"span"}
                  variant={"body2"}
                  sx={{ color: "red", mt: 0, pt: 0 }}
                >
                  {errors.password}
                </Typography>
              )
            }
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            variant={"standard"}
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            value={values.confirmPassword}
            onChange={handleChange}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            helperText={
              touched.confirmPassword &&
              errors.confirmPassword && (
                <Typography
                  component={"span"}
                  variant={"body2"}
                  sx={{ color: "red", mt: 0, pt: 0 }}
                >
                  {errors.confirmPassword}
                </Typography>
              )
            }
          />
          <Button
            sx={{ mt: 2 }}
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
          >
            Submit
          </Button>
        </form>
        <img
          src={ChangePassword}
          alt="login"
          style={{ width: 300, paddingTop:40 }}
        />
      </Stack>
    </Box>
  );
};

export default ResetPassword;
