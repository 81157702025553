import * as React from "react";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import ContextActionType from "../../models/ContextActionType";
import { ContextAction } from "../Common/ContextMenu/ContextMenuList";
import ActionList from "../Common/ContextMenu/ActionList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DatastreamAction } from "../../pages/Content/Datastreams/MenuList/DatastreamMenuList";
import DatastreamActionType from "../../models/DatastreamActionType";
import {useTranslation} from "react-i18next";

type TableMenuProps = {
  handleMenuAction:
    | ((menuAction: ContextActionType, entityID: string) => void)
    | ((action: DatastreamActionType, id?: string) => void);
  actions: ContextAction[] | DatastreamAction[];
  entityID: string;
  entityName: string;
  disabled?: boolean;
};

export default function TableMenu(props: TableMenuProps) {
  const { actions, handleMenuAction, entityID, entityName, disabled } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (actions.length === 0) return null

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size={"small"}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ActionList
          entityID={entityID}
          entityName={t(entityName).toString()}
          handleAction={handleMenuAction}
          handleClose={handleClose}
          actions={actions}
        />
      </Menu>
    </div>
  );
}
