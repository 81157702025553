import { useMemo } from "react";
import { API_URL } from "../../utils/config";
import { useFetch } from "../common/useFetch";
import { ContainerExtended } from "../../models/Container";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import ReservedUUID from "../../models/ReservedUUID";

type OptionType = {
  icon: OverridableComponent<SvgIconTypeMap>;
  title: string;
  keyValue: string;
  query: string;
  color?: string;
};

export const useLocationOptions = (
  locationQuery: string | null
): OptionType[] => {
  const isParent =
    locationQuery && locationQuery !== ReservedUUID.MyContentRoot;
  const [container] = useFetch<ContainerExtended>(
    isParent ? `${API_URL}/containers/${locationQuery}` : null,
    new ContainerExtended()
  );
  return useMemo(() => {
    return [
      {
        icon: FolderOpenOutlinedIcon,
        keyValue:"location",
        title: isParent ? `${container.label}` : "",
        query: isParent ? `${container.uuid}` : "",
      },
    ];
  }, [container, isParent]);
};
