import Dialog from "./Dialog";
import usePaletteStore, { Palette } from "../store/useThemeConfiguration";
import classic from "../theme/palettes/classic";
import pink from "../theme/palettes/pink";
import dark from "../theme/palettes/dark";
import blendo from "../theme/palettes/blendo";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import ThemeCard from "./Theme/ThemeCard";

type PaletteSelectorProps = {
  open: boolean;
  handleClose: () => void;
};

const options = [
  {
    id: Palette.classic,
    name: "toolbar.themes.classic",
    color: classic.primary.main,
    bgColor: classic.background,
  },
  {
    id: Palette.pink,
    name: "toolbar.themes.pink",
    color: pink.primary.main,
    bgColor: pink.background,
  },
  {
    id: Palette.blendo,
    name: "toolbar.themes.blendo",
    color: blendo.primary.main,
    bgColor: blendo.background,
  },
  {
    id: Palette.dark,
    name: "toolbar.themes.dark",
    color: dark.primary.main,
    bgColor: dark.background,
  },
];

function PaletteSelector(props: PaletteSelectorProps) {
  const { open, handleClose } = props;
  const {t} = useTranslation();
  const { selectPalette, palette } = usePaletteStore();

  const title = t("toolbar.themes.title");

  return (
    <Dialog
      primaryAction={handleClose}
      primaryActionTitle={t("buttons.close").toString()}
      title={title}
      open={open}
      fullWidth
      maxWidth={"sm"}
      handleClose={handleClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={palette === Palette.os}
                onClick={() => selectPalette(Palette.os)}
              />
            }
            label={t("paletteSelector.syncOS")}
          />
        </Grid>
        {options.map((pl) => (
          <Grid item key={pl.id} xs={6}>
            <ThemeCard
              onCheck={() => selectPalette(pl.id)}
              color={pl.color}
              paperColor={pl.bgColor.paper}
              activeColor={pl.bgColor.active}
              name={t(pl.name)}
              active={palette === pl.id}
            />
          </Grid>
        ))}
      </Grid>
    </Dialog>
  );
}

export default PaletteSelector;
