import React from "react";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import StorageInfo from "../../models/StorageInfo";
import { bytesToSize, percentage } from "../../utils/utils";
import {useTranslation} from "react-i18next";

type storageCardProps = {
  info: StorageInfo | undefined;
};

const StorageCard = ({ info }: storageCardProps) => {
  const { t } = useTranslation();
  const GBtotal = bytesToSize(info?.totalMaxSpace ?? 0);
  const MBused = bytesToSize(info?.totalUsableSpace ?? 0);
  const usedPercentage = percentage(
    info?.totalUsableSpace ?? 0,
    info?.totalMaxSpace ?? 0
  );

  return (
    <Card
      sx={{
        ml: 3,
        mt: 4,
        width: 200,
      }}
    >
      <CardActionArea>
        <CardContent>
          <Stack flexDirection={"column"} rowGap={2} alignItems={"flex-start"} sx={{ whiteSpace : "normal" }} >
            <Stack
              flexDirection={"row"}
              columnGap={1}
              alignItems={"center"}
            >
              <CloudOutlinedIcon
                fontSize={"medium"}
                sx={{
                  color: "primary.main",
                }}
              />
              <Typography color={"primary.main"} variant={"body2"}>
                {t("sidenav.storage")} ({usedPercentage}% {t("sidenav.storage.used")})
              </Typography>
            </Stack>
            <Typography color={"grey.850"} variant={"body2"}>
              {MBused} of {GBtotal} {t("sidenav.storage.used")}
            </Typography>
          </Stack>
          <LinearProgress
            variant="determinate"
            value={parseInt(usedPercentage)}
            sx={{ mt: 1, borderRadius: 6, height: 8 }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default StorageCard;
