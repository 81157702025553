import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";

type NavigationItemProps = {
  open: boolean;
  path: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  name: string;
  isActive: (path: string) => boolean;
};

function NavigationItem(props: NavigationItemProps) {
  const { open, path, onClick, name, isActive } = props;

  const icon = (
    <props.icon
      sx={{ color: isActive(path) && !isActive("settings") ? "primary.main" : "grey.650" }}
      fontSize={"small"}
    />
  );

  return (
    <Link to={`/${path}`} style={{ textDecoration: "none", color: "#000" }}>
      {!open ? (
        <Tooltip title={name} placement="right" arrow>
          <ListItemButton
            id={path}
            onClick={onClick}
            sx={{
              ml: 1,
              width: 55,
              height: 40,
            }}
          >
            {icon}
          </ListItemButton>
        </Tooltip>
      ) : (
        <ListItemButton
          id={path}
          onClick={onClick}
          sx={{
            ml: 1,
            width: 232,
            height: 40,
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>
            <Typography
              color={
                isActive(path) && !isActive("settings")
                  ? "primary.main"
                  : "grey.650"
              }
              variant={"body2"}
            >
              {name}
            </Typography>
          </ListItemText>
        </ListItemButton>
      )}
    </Link>
  );
}

export default NavigationItem;
