import {
  Dialog as MuiDialog
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Datastream } from "../../models/Datastream";
import { useIAMPermission } from "../../store/userStore";
import IAMPermission from "../../models/IAMPermission";
import { useMap } from "../../hooks/common/useMap";
import { useMemo } from "react";
import { useFetch } from "../../hooks/common/useFetch";
import { API_URL } from "../../utils/config";
import { useHasPreview } from "../../hooks/datastream/useHasPreview";
import { useNavigate } from "react-router-dom";
import NoPreviewAvailable from "../Common/NoPreviewAvailable";
import { lazy, Suspense } from 'react';
const DatastreamPreview = lazy(() => import('./DatastreamPreview'));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  handleDownload: () => void;
  previewUuid: string | null;
  isShortcut: boolean;
  shortcuts: Datastream[];
  toNavigate: boolean;
  token?: string;
};

function PreviewDialog({
  open,
  handleClose,
  previewUuid,
  isShortcut,
  shortcuts,
  handleDownload,
  toNavigate,
  token
}: DialogProps) {
  const iam = useIAMPermission();
  const navigate = useNavigate();

  const shortcutMap = useMap(shortcuts, "uuid");

  const uuid = useMemo(() => {
    if (!open && !previewUuid) return "";
    if (isShortcut) {
      const shortcut = shortcutMap.get(previewUuid) ?? new Datastream();
      return shortcut.objectId;
    }
    return previewUuid;
  }, [previewUuid, isShortcut, shortcutMap, open]);

  const [data, isLoading] = useFetch<Datastream>(
    uuid ? (token ? `${API_URL}/datastreams/${uuid}?tk=${token}` : `${API_URL}/datastreams/${uuid}` ) : null,
    new Datastream()
  );

  const { hasPreview, deleted } = useHasPreview(data.bitstreamFile?.mimeType, data.bitstreamFile?.filename);

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "black",
          color: "white",
        },
      }}
    >
      {isLoading && <LinearProgress style={{ marginBottom: -4 }} />}

      <DialogTitle id="alert-dialog-title">
        {isLoading ? (
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={1}
          >
            <Typography variant={"caption"}>loading datastream...</Typography>
          </Stack>
        ) : (
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={1}
          >
            <Typography variant={"caption"}>{data.bitstreamFile?.filename}</Typography>
            <div style={{ flexGrow: 1 }}></div>
            {iam.has(IAMPermission.store_datastream_download) && !deleted && (
              <Tooltip title={"Download Datastream"} placement={"bottom"} arrow>
                <IconButton
                  size={"small"}
                  aria-label="close"
                  onClick={handleDownload}
                  sx={{
                    color: "common.white",
                  }}
                >
                  <DownloadOutlinedIcon fontSize={"small"} />
                </IconButton>
              </Tooltip>
            )}
            <IconButton
              size={"small"}
              aria-label="close"
              onClick={
                !toNavigate
                  ? handleClose
                  : () => navigate(`/store/content/${data.parent}`)
              }
              sx={{
                color: "common.white",
              }}
            >
              <CloseIcon fontSize={"small"} />
            </IconButton>
          </Stack>
        )}
      </DialogTitle>
      {!isLoading && (
        <DialogContent>
          <Stack
            sx={{
              maxHeight: "80%",
            }}
          >
            {hasPreview ? (
              <Suspense fallback={
                <Stack
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height={"100%"}
                >
                  <CircularProgress />
                </Stack>
              }>
                <DatastreamPreview
                  fileType={data?.bitstreamFile?.mimeType ?? ""}
                  viewUrl={data.bitstreamFile?.viewUrl}
                  filename={data?.bitstreamFile?.filename ?? ""}
                  datastreamUUID={data.uuid}
                />
              </Suspense>
            ) : (
              <Stack sx={{ mt: 2 }} alignItems={"center"}>
                {deleted ? (
                  <Typography variant={"caption"}>
                    The original datastream has been deleted
                  </Typography>
                ) : (
                <NoPreviewAvailable />
                )}
              </Stack>
            )}
          </Stack>
        </DialogContent>
      )}
    </MuiDialog>
  );
}

export default PreviewDialog;
