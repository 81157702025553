import * as React from "react";
import IconButton from "@mui/material/IconButton";
import {SxProps} from "@mui/material";
import MenuList from "@mui/material/MenuList";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import { ReactNode } from "react";

type MenuButtonProps = {
  icon: ReactNode;
  sx?: SxProps;
  menuWidth?: string;
  menu: JSX.Element;
  menuMargin?: number;
};

export default function MenuButton(props: MenuButtonProps) {
  const { icon, sx, menuWidth, menu, menuMargin } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack flexDirection={"column"} alignItems={"flex-end"}>
      <IconButton
        sx={{
          "&:hover": {
            background: "none",
          },
          ...sx,
        }}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: menuWidth && menuWidth,
            marginTop: menuMargin,
          },
        }}
      >
        <MenuList onClick={handleClose}>
          {React.cloneElement(menu, {
            handleDismiss: () => setAnchorEl(null),
          })}
        </MenuList>
      </Popover>
    </Stack>
  );
}
