import { useSchemaList } from "../../store/schemaStore";
import { useEffect, useState } from "react";
import Schema from "../../models/Schema";
import {useContainerTypeList} from "../../store/containerTypeStore";
import {useMap} from "../common/useMap";
import {fetchData} from "../../utils/utils";
import {API_URL} from "../../utils/config";

const useContainerSchema = (containerTypeUUID?: string): [Schema, boolean] => {
  const [schemas] = useSchemaList();
  const [containerTypes] = useContainerTypeList();
  const containerTypeMap = useMap(containerTypes, "uuid")
  const schemaMap = useMap(schemas, "uuid")
  const [schema, setSchema] = useState<Schema>(new Schema());
  const [inProgress, setInProgress] = useState(false)

  useEffect(() => {
    if (!containerTypeUUID) return setSchema(new Schema());

    const type = containerTypeMap.get(containerTypeUUID)
    const schema = schemaMap.get(type?.schemaUuid)
    if (!schema) return setSchema(new Schema());

    setInProgress(true)
    fetchData(`${API_URL}/cm/schemas/${schema.uuid}`)
      .then((res: Schema) => {
        setSchema(res);
        setInProgress(false);
      })
      .catch((ex) => {
        console.log(ex);
        setInProgress(false)
      });

  }, [containerTypeMap, containerTypeUUID, schemaMap, schemas]);

  return [schema, inProgress]
};

export default useContainerSchema
