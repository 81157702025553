import {
  Dialog as MuiDialog
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import React, {Fragment, ReactNode} from "react";
import { Breakpoint } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import ErrorBoundary from "../ErrorBoundary";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSystemSettingKey} from "../../store/systemSettingsStore";
import {SystemSettingKey} from "../../models/SystemSetting";
import {ArrowBack} from "@mui/icons-material";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  primaryActionTitle?: string;
  secondaryActionTitle?: string;
  children: React.ReactNode;
  title?: string;
  renderTitle?: false | (() => ReactNode);
  maxWidth?: Breakpoint | false;
  fullWidth?: boolean;
  onSubmit?: (e?: any) => void;
  inProgress?: boolean;
  fullScreen?: boolean;
  disablePrimaryAction?: boolean;
  handleBack? : false | (() => void)
  edgeToEdge?: boolean;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

type ContainerProps = {
  onSubmit?: () => void;
  children: ReactNode;
};
const Container = ({ onSubmit, children }: ContainerProps) => {
  return onSubmit ? (
    <form id={"myForm"} onSubmit={onSubmit}>
      {children}
    </form>
  ) : (
    <Stack>{children}</Stack>
  );
};

function Dialog({
  handleClose,
  open,
  children,
  title,
  renderTitle,
  primaryActionTitle = "Confirm",
  secondaryActionTitle = "Cancel",
  secondaryAction,
  primaryAction,
  maxWidth,
  fullWidth,
  onSubmit,
  inProgress,
  disablePrimaryAction,
  handleBack,
  fullScreen,
  edgeToEdge
}: DialogProps) {

  const preventDismiss = useSystemSettingKey(SystemSettingKey.ui_dialog_prevent_dismiss, false)

  return (
    <MuiDialog
      open={open}
      onClose={(_, reason) => {
        if (preventDismiss) {
          if (reason !== "backdropClick") {
            handleClose();
          }
        } else {
          handleClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      // @ts-ignore
      TransitionComponent={Transition}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
    >
      {inProgress && <LinearProgress style={{ marginBottom: -4 }} />}

      {title && (
        <DialogTitle id="alert-dialog-title">
          <Stack direction={"row"} alignItems={"center"} columnGap={1}>
            {handleBack && (
              <Fragment>
                <IconButton
                  onClick={handleBack}
                  size={"small"}
                  sx={{
                    position: "absolute",
                    left: 16,
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.common.white
                        : theme.palette.common.black,
                  }}
                >
                  <ArrowBack fontSize={"small"} />
                </IconButton>
                <Box sx={{ width: 24 }} />
              </Fragment>
            )}
            <Typography variant={"body1"}>{title}</Typography>
          </Stack>
          <IconButton
            size={"small"}
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.common.white
                  : theme.palette.common.black,
            }}
          >
            <CloseIcon fontSize={"small"} />
          </IconButton>
        </DialogTitle>
      )}
      {renderTitle && renderTitle()}
      <DialogContent dividers>
        <Container onSubmit={onSubmit}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </Container>
      </DialogContent>
      {(primaryAction || onSubmit || secondaryAction) && (
        <DialogActions>
          {secondaryAction && (
            <Button onClick={secondaryAction}>{secondaryActionTitle}</Button>
          )}
          {(primaryAction || onSubmit) && (
            // @ts-ignore
            <LoadingButton
              loading={inProgress}
              variant={"contained"}
              disabled={disablePrimaryAction}
              type={onSubmit ? "submit" : "button"}
              onClick={primaryAction}
              autoFocus
              form={onSubmit ? "myForm" : undefined}
            >
              {primaryActionTitle}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
}

export default Dialog;
