export enum ObjectType {
  API_KEY = "API_KEY",

  SETTING = "SETTING",

  SEARCH_REQUEST = "SEARCH_REQUEST",

  CONTAINER = "CONTAINER",
  GUIDE = "GUIDE",
  GEO = "GEO",
  DATASTREAM = "DATASTREAM",
  THUMBNAIL = "THUMBNAIL",

  EXPORT_REPOX_ARCHIVE = "EXPORT_REPOX_ARCHIVE",
  IMPORT_REPOX_ARCHIVE = "IMPORT_REPOX_ARCHIVE",
  EXPORT_XLSX = "EXPORT_XLSX",
  IMPORT_XLSX = "IMPORT_XLSX",

  CM_CONTAINER_TYPE = "CM_CONTAINER_TYPE",
  CM_DATASTREAM_TYPE = "CM_DATASTREAM_TYPE",
  CM_SCHEMA = "CM_SCHEMA",
  CM_ATTRIBUTE = "CM_ATTRIBUTE",
  CM_PROPERTY = "CM_PROPERTY",
  CM_PROPERTY_GROUP = "CM_PROPERTY_GROUP",
  CM_PROPERTY_NAMESPACE = "CM_PROPERTY_NAMESPACE",
  CM_VOCABULARY = "CM_VOCABULARY",
  CM_MAPPING = "CM_MAPPING",

  USER = "USER",
  GROUP = "GROUP",

  SRV_SURVEY = "SRV_SURVEY",

  GD_GUIDE = "GD_GUIDE",
  GD_COMPONENT = "GD_COMPONENT",
  GD_ITEM = "GD_ITEM",
  GD_PAGE = "GD_PAGE",

  STORY = "STORY",
  SCREEN = "SCREEN",
  WEBSITE = "WEBSITE",

  PORTAL = "PORTAL",

  COMMENT = "COMMENT",

  XR_APPLICATION = "XR_APPLICATION",
  XR_COLLECTION = "XR_COLLECTION",
  XR_ANCHOR = "XR_ANCHOR",
}
