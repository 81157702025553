import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import TheatersOutlinedIcon from "@mui/icons-material/TheatersOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import WebIcon from "@mui/icons-material/Web";
import MapIcon from '@mui/icons-material/Map';

import {
  GUIDE_PERMISSIONS,
  STORE_PERMISSIONS,
  STORY_PERMISSIONS,
  SURVEY_PERMISSIONS,
  XR_PERMISSIONS,
  PORTAL_PERMISSIONS,
  GEO_PERMISSIONS
} from "../models/IAMPermission";

export const repoxApps = [
  {
    name: "Store",
    icon: <CloudQueueIcon/>,
    path: "/store/content",
    disabled: false,
    permissions: STORE_PERMISSIONS
  },
  {
    name: "Surveys",
    icon: <PollOutlinedIcon/>,
    path: "/surveys",
    disabled: false,
    permissions: SURVEY_PERMISSIONS
  },
  {
    name: "Stories",
    icon: <TheatersOutlinedIcon/>,
    path: "/stories",
    disabled: false,
    permissions: STORY_PERMISSIONS
  },
  {
    name: "Guide",
    icon: <ExploreOutlinedIcon/>,
    path: "/guide",
    disabled: false,
    permissions: GUIDE_PERMISSIONS
  },
  {
    name: "Portal",
    icon: <WebIcon/>,
    path: "/portal",
    disabled: false,
    permissions: PORTAL_PERMISSIONS
  },
  {
    name: "XR",
    icon: <ViewInArIcon/>,
    path: "/xr/applications",
    disabled: false,
    permissions: XR_PERMISSIONS
  },
  {
    name: "GEO",
    icon: <MapIcon/>,
    path: "/geo",
    disabled: false,
    permissions: GEO_PERMISSIONS
  },
]
