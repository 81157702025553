import React, { Fragment, ReactNode, useState } from "react";
import { Badge, Stack } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { common } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import PaletteSelector from "../../../components/PaletteSelector";
import AccountPopover from "../../../components/UserAccount/AccountPopover";
import LanguagePicker from "../../../components/LanguagePicker";
import { flags } from "flags";
import NotificationIcon from "../../../components/NotificationIcon";
import IAMPermission from "../../../models/IAMPermission";
import RepoxApps from "../../../components/RepoxApps";
import { Link } from "react-router-dom";
import { Chat } from "@mui/icons-material";
import { useIAMPermission } from "store/userStore";

type DefaultHeaderProps = {
  renderSearch?: () => ReactNode;
  renderNotifications: boolean;
  showChatGPT?: boolean
};

const btnStyles = {
  "&:hover": {
    backgroundColor: alpha(common.white, 0.25),
  },
  borderRadius: "8px",
};

const iconStyles = {
  color: "common.white",
  width: 24,
};

const Header = ({ renderNotifications, renderSearch, showChatGPT }: DefaultHeaderProps) => {
  const [openTheme, setOpenTheme] = useState(false);

  const iam = useIAMPermission();


  return (
    <Fragment>
      {renderSearch && renderSearch()}
      {iam.has(IAMPermission.ai_conversation_view) && showChatGPT && (
        <Badge badgeContent={"New"} color="success">
          <Link to={"/store/chat-gpt"}>
            <IconButton sx={btnStyles}>
              <Chat sx={iconStyles} />
            </IconButton>
          </Link>
        </Badge>
      )}
      <div style={{ flexGrow: 1 }}></div>
      <Stack flexDirection={"row"} columnGap={1} alignItems={"center"}>
        {flags.hasTranslation && <LanguagePicker />}
        <IconButton
          sx={btnStyles}
          id="demo-positioned-button"
          aria-controls={openTheme ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openTheme ? "true" : undefined}
          onClick={() => {
            setOpenTheme(true);
          }}
        >
          <ColorLensIcon sx={{ color: "common.white", width: 24 }} />
        </IconButton>
        {flags.hasRepoxApps && <RepoxApps />}

        {renderNotifications && <NotificationIcon />}

        <AccountPopover />
      </Stack>
      <PaletteSelector
        open={openTheme}
        handleClose={() => setOpenTheme(false)}
      />
    </Fragment>
  );
};

export default Header;
