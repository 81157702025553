import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { Link } from "react-router-dom";
import FilterList from "./FilterList";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { DatastreamThumb } from "../DatastreamThumb/DatastreamThumb";
import { ActualDate } from "../../utils/utils";
import Container from "../../models/Container";
import {FullTextSearchDatastream} from "../../models/Datastream";

type GlobalSearchPopperProps = {
  value: string;
  containers: Container[];
  datastreams: FullTextSearchDatastream[];
  handleClose: () => void;
  onFilterClick: (parameters: string) => void;
};

export const GlobalSearchPopper = ({
  value,
  containers,
  datastreams,
  handleClose,
  onFilterClick,
}: GlobalSearchPopperProps) => {
  return (
    <Paper
      sx={{
        width: "600px",
        height: "auto",
        overflow: "hidden",
      }}
      elevation={6}
    >
      {value ? (
        <MenuList
          sx={{
            maxHeight: "320px",
          }}
        >
          {containers.map((item) => (
            <Link
              key={item.uuid}
              to={`/store/content/${item.uuid}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem id={item.uuid} onClick={handleClose} >
                <Stack
                  flexDirection={"row"}
                  columnGap={4}
                  alignItems={"center"}
                  flexGrow={1}
                >
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      width: "50px",
                      height: "50px",
                    }}
                  >
                    <IconButton
                      size={"medium"}
                      sx={{
                        backgroundColor: "secondary.main",
                        "&:hover": {
                          backgroundColor: "secondary.main",
                        },
                      }}
                    >
                      <FolderOpenIcon />
                    </IconButton>
                  </Stack>
                  <Stack
                    flexDirection={"column"}
                    rowGap={0.5}
                    sx={{
                      width: "350px",
                    }}
                  >
                    <Typography noWrap variant={"body1"}>
                      {item.label}
                    </Typography>
                    <Typography variant={"body2"}>
                      {item.createdBy.firstname} {item.createdBy.lastname}
                    </Typography>
                  </Stack>
                  <div style={{ flexGrow: 1 }}></div>
                  <Typography variant={"body2"}>
                    {ActualDate(item.createdAt)}
                  </Typography>
                </Stack>
              </MenuItem>
            </Link>
          ))}
          {datastreams.map((item) => (
            <Link
              key={item.uuid}
              to={`/store/content/${item.parent}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem id={item.uuid} onClick={handleClose}>
                <Stack
                  flexDirection={"row"}
                  columnGap={4}
                  alignItems={"center"}
                  flexGrow={1}
                >
                  <DatastreamThumb
                    thumb={!!item.bitstreamFile?.thumbnails.mediumUrl}
                    thumbnailUrl={item.bitstreamFile?.thumbnails.mediumUrl ?? ""}
                    mimeType={item.bitstreamFile?.mimeType ?? ""}
                    objectType={item.objectType}
                    repoxType={item.repoxType}
                    objectId={item.objectId}
                    tooltip={false}
                  />
                  <Stack
                    flexDirection={"column"}
                    rowGap={0.5}
                    sx={{
                      width: "350px",
                    }}
                  >
                    <Typography noWrap variant={"body1"}>
                      {item.label}
                    </Typography>
                    <Typography variant={"body2"}>
                      {item.createdBy.firstname} {item.createdBy.lastname}
                    </Typography>
                  </Stack>
                  <div style={{ flexGrow: 1 }}></div>
                  <Typography variant={"body2"}>
                    {ActualDate(item.createdAt)}
                  </Typography>
                </Stack>
              </MenuItem>
            </Link>
          ))}
          {!datastreams.length && !containers.length && (
            <MenuItem disabled={true}>
              <ListItemText sx={{ textAlign: "center" }}>
                <Typography variant={"body1"}>No results</Typography>
              </ListItemText>
            </MenuItem>
          )}
        </MenuList>
      ) : (
        <FilterList onFilterClick={onFilterClick} handleClose={handleClose} />
      )}
    </Paper>
  );
};
