import Stack from "@mui/material/Stack";
import * as React from "react";
import Dialog from "../../../components/Dialog";
import useFormElements, { ElementType } from "../../../hooks/form/useFormElements";
import { useFormik } from "formik";
import {fetchData, postData} from "../../../utils/utils";
import { API_URL } from "../../../utils/config";
import FeedBack from "../../../models/FeedBack";
import {useSnackbar} from "notistack";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
  message?: FeedBack;
};

const formElements = [
  {
    id: "subject",
    label: "formElement.subject",
    type: ElementType.text,
    isRequired: true,
    columns: 12,
  },
  {
    id: "message",
    label: "formElement.message",
    type: ElementType.textArea,
    isRequired: true,
    columns: 12,
    rows: 6,
  },
];

function FeedbackDialog({ open, handleClose, message }: DialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {t} = useTranslation();
  const {
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: new FeedBack(),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const payload = {
        subject: values.subject,
        message: values.message,
      };
      try {
        await postData(`${API_URL}/feedback`, payload);
        enqueueSnackbar("Thank you for your feedback", { variant: "info" });
        handleClose();
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    setValues(new FeedBack())
    if (!message || !open) return
    fetchData(`${API_URL}/feedback/${message.uuid}`)
      .then(data => {
        setValues(data)
      })
      .catch(ex => {
        console.log(ex)
      })
  }, [message, setValues, open])

  const onClose=()=>{
    resetForm();
    handleClose();
  }

  const form = useFormElements({
    formElements,
    values,
    handleChange,
    inProgress: isSubmitting,
    variant: "standard",
    allowEdit: !message,
    setValues
  });

  return (
    <Dialog
      open={open}
      title={message ? t("feedback.modal.title.view").toString() : t("feedback.modal.title.submit").toString()}
      handleClose={onClose}
      secondaryAction={!message ? onClose : undefined}
      onSubmit={!message ? handleSubmit : undefined}
      primaryAction={message ? onClose : undefined}
      primaryActionTitle={!message ? t("buttons.submit").toString() : t("buttons.ok").toString()}
      secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
      maxWidth={"sm"}
      fullWidth
    >
      <Stack rowGap={2} sx={{m: 1 }}>
        {form}
      </Stack>
    </Dialog>
  );
}

export default FeedbackDialog;
