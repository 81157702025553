import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Card, Grid, Stack, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import Survey from "../../models/Survey";
import { SurveyType } from "../../models/SurveyItem";
import { API_URL } from "../../../../utils/config";
import { deleteData, fetchData, postData } from "../../../../utils/utils";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import ActionToolbar from "../../../guide/components/ActionToolbar";
import withAuth from "../../../../auth/withAuth";
import mockData from "../../mockData/srv.surveys.uuid.json";
import { Field } from "../../components/fields/ShortAnswer";
import Form from "../../components/Form";
import Activity from "../Activity";
import Responses from "../../responses/Responses";
import SendSurveyDialog from "../../components/SendSurveyDialog";
import SendIcon from '@mui/icons-material/Send';
function SurveyDetails() {
  // eslint-disable-next-line
  const { t } = useTranslation();

  const params = useParams();
  const uuid = params.surveyUUID;

  const [survey ,setSurvey] = useState<Survey>(new Survey(SurveyType.ANONYMOUS));
  const [fields, setFields] = useState<Field[]>([]);
  const [isEditing] = useState(Boolean(uuid));
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [sendDialogOpen, setSendDialogOpen] = useState(false);

  const handleSave = () => {
    handleSubmit();
  };

  // eslint-disable-next-line
  const { values, setValues, handleSubmit, setFieldValue } = useFormik({
    initialValues: new Survey(SurveyType.ANONYMOUS),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const url = `${API_URL}/srv/surveys/${uuid}`;

        const payload = {

        };
        await postData(url, payload);
        navigate(`/surveys`);
      } catch (ex: any) {
        enqueueSnackbar(ex.message, { variant: "error" });
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!isEditing) return;
    setLoading(true);
    fetchData(`${API_URL}/srv/surveys/${uuid}`)
      .then((data) => {
        setValues(data)
        setSurvey(data)
        setLoading(false);
      })
      .catch((ex) => {
        if (mockData) {
          // @ts-ignore
          setValues(mockData);
          // @ts-ignore
          setSurvey(mockData);
          // @ts-ignore
          setFields(mockData.questions);
        } else {
          enqueueSnackbar(ex.message, { variant: "error" });
        }
        setLoading(false);
      });
  }, [enqueueSnackbar, uuid, setValues, isEditing]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDelete = async () => {
    try {
      const url = `${API_URL}/srv/surveys/${uuid}`;
      await deleteData(url);
      navigate(`/surveys`);
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setOpenDeleteDialog(false);
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Stack>
      <ConfirmationDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        primaryAction={handleDelete}
        title={`Delete survey`}
        message={`Are you sure you want to delete this survey?`}
        primaryActionTitle={"Confirm"}
        inProgress={false}
      />

      {!loading && <Card sx={{ p: 3 }}>
        <Stack direction={"column"} rowGap={3} key={uuid}>
          <ActionToolbar
            backUrl={`/surveys`}
            onClickActionButton={handleSave}
          >
            <Button variant="contained" color="primary" onClick={() => setSendDialogOpen(true)}>
              <SendIcon /> &nbsp; Share
            </Button>
            <Button variant="outlined" color="error" onClick={() => setOpenDeleteDialog(true)}>
              <DeleteIcon /> &nbsp; Delete
            </Button>

          </ActionToolbar>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={2}>
            <Tabs centered value={tabValue} onChange={handleChangeTab} aria-label="Survey Tabs">
              <Tab label="Survey" {...a11yProps(0)} />
              <Tab label={`Responses ${Boolean(survey.responseCount)?`(${survey.responseCount})`:''}`} {...a11yProps(1)} />
              <Tab label="Activity" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <SendSurveyDialog open={sendDialogOpen} handleClose={()=>setSendDialogOpen(false)} />

          <Stack>
            {tabValue===0&&<Stack direction={'row'} gap={2}>
              <Form fields={fields} setFields={setFields} survey={survey} setSurvey={setSurvey} disabled={loading} />
            </Stack>}

            {tabValue===1&&<Grid item container spacing={2}>
              <Responses surveyData={survey} />
            </Grid>}

            {tabValue===2&&<Stack direction={'row'} gap={2}>
              <Activity />
            </Stack>}
          </Stack>

        </Stack>
      </Card>}

    </Stack>
  );
}

export default withAuth(SurveyDetails);
