import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import React from "react";

type PropertyReadOnlyPreviewProps = {
  name: string
  value?: string
  required?: boolean
  recommended?: boolean
}

function PropertyReadOnlyPreview({ value, name, required, recommended }: PropertyReadOnlyPreviewProps) {
  return (
    <Stack rowGap={0.5} flex={1}>
      <Typography variant={"caption"} color={"textSecondary"}>
        {name} {required && "*"} {recommended && <sup>(Rec)</sup>}
      </Typography>
      <Typography variant={"body1"}>{value || "-"}</Typography>
    </Stack>
  )
}
export default PropertyReadOnlyPreview
