import {
  Checkbox,
  TableCell,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {useSelectedList} from "../common/useSelectedList";
import TableMenu from "../../components/DefaultTable/TableMenu";
import * as React from "react";
import {ContextAction} from "../../components/Common/ContextMenu/ContextMenuList";
import contextActionType from "../../models/ContextActionType";
import {useTranslation} from "react-i18next";

export const useDefaultTable = (
  array: any,
  sortHandler: any,
  identifier: string,
  hideBorders: boolean = true,
  multiSelect: boolean = true
) => {

  const {
    setSelected,
    // @ts-ignore
    setSelectedRows,
    handleClick,
    isSelected,
    numSelected,
    selected,
    selectedRows,
    handleSelectAllClick,
  } = useSelectedList(array, identifier, multiSelect);

  const { t } = useTranslation();
  const rowCount = array.length;

  const borderBottom = hideBorders ? "none" : "1px solid #DFE3E8";

  const renderHeadCell = (headCell: any) => {

    return (
      <TableCell
        sortDirection={"asc"}
        key={headCell.id}
        align={headCell.numeric ? "right" : "left"}
        // @ts-ignore
        padding={headCell.disablePadding ? "none" : "normal"}
        sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}
        style={{borderBottom}}
      >
        {headCell.isSortable && sortHandler ? (
          <TableSortLabel
            active={sortHandler.sort.by === headCell.id}
            direction={sortHandler.sort.direction}
            onClick={() => sortHandler.handleSort(headCell.id)}
          >
            <Typography noWrap sx={{ fontWeight: "bold"}} variant={"body2"}>
              {t(headCell.label)}
            </Typography>
          </TableSortLabel>
        ) : (
          <Typography noWrap sx={{ fontWeight: "bold"}} variant={"body2"}>
            {t(headCell.label)}
          </Typography>
        )}
      </TableCell>
    );
  };

  const renderBodyCell = (label: any, stylesOverride = {}) => {
    return (
      <TableCell align="left" sx={{ borderBottom, ...stylesOverride }} >
        <Typography variant={"body2"} style={{ fontWeight: "inherit" }}>
          {label}
        </Typography>
      </TableCell>
    );
  };

  const renderCheckAllCell = () => {
    return (
      <TableCell padding="checkbox"  style={{borderBottom}}>
        <Checkbox
          disabled={!multiSelect}
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={handleSelectAllClick}
          inputProps={{"aria-label": "select all desserts"}}
        />
      </TableCell>
    );
  };

  const renderCheckRowCell = (
    isItemSelected: boolean,
    labelId: string,
    onClick: (event: Event) => void
  ) => {
    return (
      // @ts-ignore
      <TableCell  padding="checkbox" onClick={onClick} style={{borderBottom}}>
        <Checkbox
          checked={isItemSelected}
          inputProps={{"aria-labelledby": labelId}}
        />
      </TableCell>
    );
  };

  const renderDefaultBody = (
    array: any,
    headerColumns: any,
    selectableRows: boolean,
    onClick: (arg: any) => void,
    styleCell: any,
    actions?: ContextAction[],
    handleAction?: (actionID: contextActionType, entityID: string) => void
  ) => {
    return array.map((row: any, index: number) => {
      const isItemSelected = isSelected(row[identifier]);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <TableRow
          key={index}
          hover
          onClick={(event) => {
            if (onClick) {
              onClick(row);
            } else {
              if (selectableRows) {
                // @ts-ignore
                handleClick(event, row[identifier]);
              }
            }
          }}
          sx={{ backgroundColor: "background.paper" }}
          // sx={{ backgroundColor: "background.paper", borderRadius: 2  }}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={isItemSelected}
        >
          {selectableRows &&
            renderCheckRowCell(isItemSelected, labelId, (event) =>
              handleClick(event, row[identifier])
            )}
          {headerColumns.map((column: any) => {
            const styles = styleCell && styleCell(column.id, row[column.id], row);
            return renderBodyCell(row[column.id], styles);
          })}
          {actions && handleAction &&
            <TableCell
              style={{ width: 0, border: 0 }}

              onClick={(e) => e.stopPropagation()}
            >
              <TableMenu
                handleMenuAction={handleAction}
                actions={actions}
                entityID={row[identifier]}
                entityName={row.label || row.name || "Unknown"}
              />
            </TableCell>
          }
        </TableRow>
      );
    });
  };

  return {
    handleClick,
    handleSelectAllClick,
    isSelected,
    setSelected,
    setSelectedRows,
    renderHeadCell,
    renderBodyCell,
    renderCheckAllCell,
    renderCheckRowCell,
    renderDefaultBody,
    numSelected,
    rowCount,
    selected,
    selectedRows,
  };
};
