import RepoxAppsMenu from "../../layouts/CoreLayout/Header/RepoxAppsMenu";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import MenuButton from "../MenuButton/MenuButton";
import React from "react";
import { alpha } from "@mui/material/styles";
import { common } from "@mui/material/colors";
import { repoxApps } from "../../constants/repox-apps";
import { useIAMPermission } from "../../store/userStore";
const btnStyles = {
  "&:hover": {
    backgroundColor: alpha(common.white, 0.25),
  },
  borderRadius: "8px",
};

const iconStyles = {
  color: "common.white",
  width: 24,
};
const RepoxApps = () => {
  const iam = useIAMPermission();
  const apps = repoxApps.filter((x) => iam.hasSome(x.permissions));
  if (apps.length === 1 && apps[0]?.name === "Store") {
    return null;
  }
  return (
    <MenuButton
      menu={<RepoxAppsMenu apps={apps} />}
      icon={<DomainAddOutlinedIcon sx={iconStyles} />}
      sx={btnStyles}
      menuMargin={8}
    />
  );
};

export default RepoxApps;
