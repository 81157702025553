import { create } from "zustand";
import { createCommonStore, Entity, useEntityList } from "./core";
import { API_URL } from "../utils/config";
import DatastreamType from "../models/DatastreamType";

export const useDatastreamTypeStore = create<Entity<DatastreamType>>(
  createCommonStore(`${API_URL}/cm/datastream_types/all`, "uuid")
);

export const useDatastreamTypeList = (): [DatastreamType[], boolean] => {
  const store = useDatastreamTypeStore();
  return useEntityList(store);
};
