import React from "react";
import { Divider, List, ListItemText, Typography } from "@mui/material";
import NavigationItem from "../../../../layouts/CoreLayout/NavigationItem";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import ExploreIcon from '@mui/icons-material/Explore';

type NavigationProps = {
  open: boolean;
};

function Navigation({ open }: NavigationProps) {
  const location = useLocation();

  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };

  return (
    <List>
      {GuidesMenuItems().map((tag:any, index) => {

          if (tag.title) {
            return <ListItemText
            sx={{
              display: 'flex',
              // paddingLeft: 3,
              height: '30px',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {!open?<hr />:<Typography variant={'body1'}>{tag.title}</Typography>}
            </ListItemText>
          }

          if (tag.separator) {
            return <ListItemText> <Divider /> </ListItemText>
          }

          return <NavigationItem
            key={index}
            open={open}
            path={tag.path}
            icon={tag.icon}
            name={tag.name}
            isActive={isActive}
          />

      })}
    </List>
  );
}

export default Navigation;

export const GuidesMenuItems = () => {
  const { t } = useTranslation();

  return [
  {
    icon: ExploreIcon,
    name: t("guides.navigation.guides"),
    path: "guide/applications",
    color: "primary.main",
    description: "",
  }
]}

