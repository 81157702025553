import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paging from "../../../models/Pageable";
import { MediaAsset } from "../../../models/MediaAsset";
import BlankCanvas from "../../../assets/images/undraw_blank_canvas_re_2hwy.svg";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import MediaCard from "./MediaCard";
import { deleteData, updateData } from "../../../utils/utils";
import { useSnackbar } from "notistack";
import { API_URL } from "../../../utils/config";
import MediaForm from "./MediaForm";
import { BitstreamFile } from "../../../models/Datastream";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmationDialog from "../../ConfirmationDialog";
import { useTranslation } from "react-i18next";

type MediaGridProps = {
  result: Paging<MediaAsset>;
  loading: boolean;
  handleAdd: () => void;
  handleSelect?: (uuid: string) => void;
  checkable?: boolean;
  selectedAssets?: string[];
  handleRefresh: () => void;
  filetypes?: string[];
};

const heights = [250, 250, 250, 250];

const initialState = {
  label: "",
  altText: "",
  description: "",
  uuid: "",
  bitstreamFile: new BitstreamFile(),
};

function MediaGrid(props: MediaGridProps) {
  const {
    result,
    loading,
    handleAdd,
    handleSelect,
    selectedAssets,
    checkable,
    handleRefresh,
  } = props;

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [form, setForm] = useState(initialState);
  const [inProgress, setInProgress] = useState(false);
  const {t} = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id;
    const value = e.currentTarget.value;
    setForm((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }, []);

  const handleUpdate = useCallback(async () => {
    setInProgress(true);
    try {
      await updateData(`${API_URL}/media_library/assets/${form.uuid}`, form);
      await handleRefresh();
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setInProgress(false);
  }, [enqueueSnackbar, form, handleRefresh]);

  const handleDelete = async () => {
    setInProgress(true);
    setOpenDelete(false)
    try {
      await deleteData(`${API_URL}/media_library/assets/${form.uuid}`, form);
      await handleRefresh();
      setOpenDelete(false)
      if (handleSelect && checkable && selectedAssets?.includes(form.uuid)) {
        // @ts-ignore
        onCheck({ target: { id: asset.uuid } });
      }
    } catch (ex: any) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
    setInProgress(false);
  };

  const onEditAsset = useCallback((asset: MediaAsset) => {
    setForm(asset);
    setOpenEdit(true);
  }, []);

  useEffect(() => {
    setOpenEdit(false)
  }, [result.content.length])

  const isEmpty = result.totalElements === 0;
  const selectedAsset = result.content.find(x => x.uuid === form.uuid)

  return (
    <Stack direction={"row"} columnGap={2} alignItems={"flex-start"}>
      {loading && (
        <Stack direction={"row"} columnGap={2} flex={1}>
          <Stack rowGap={1} columnGap={1} direction={"row"} flexWrap={"wrap"}>
            {heights.map((index) => {
              return <Stack key={index} width={200}>
                <Skeleton width={"100%"} height={230} />
              </Stack>
            })}
          </Stack>
        </Stack>
      )}
      {isEmpty && !loading && (
        <Stack
          flexDirection={"column"}
          m={"auto"}
          alignItems={"center"}
          rowGap={3}
          p={2}
        >
          <img
            src={BlankCanvas}
            alt="login"
            style={{ width: 250, height: 250 }}
          />
          <Typography>No results</Typography>
          <Button
            onClick={handleAdd}
            startIcon={<AddIcon />}
            style={{ textTransform: "none" }}
          >
            Add items to media library
          </Button>
        </Stack>
      )}
      {!loading && result.content.length > 0 && (
        <Stack direction={"row"} columnGap={2} flex={1}>
          <Stack rowGap={1.5} columnGap={1.5} direction={"row"} flexWrap={"wrap"}>
            {result.content.map((asset) => {
              return <Stack key={asset.uuid} width={217.5}>
                <MediaCard
                  checked={selectedAssets?.includes(asset.uuid)}
                  checkable={checkable && !openEdit}
                  onCheck={handleSelect}
                  asset={asset}
                  onEdit={onEditAsset}
                />
              </Stack>
            })}
          </Stack>
          {openEdit && (
            <Card sx={{ minWidth: 420 }}>
              <Stack direction={"row"} pl={2} pr={1} py={1} alignItems={"center"}>
                <Typography flex={1} noWrap>
                  {selectedAsset?.label}
                </Typography>
                <IconButton onClick={() => setOpenEdit(false)}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider />
              <Stack
                p={2}
                sx={{ width: "100%", position: "relative" }}
                rowGap={2}
              >
                <MediaForm form={form} handleChange={handleChange} />
                <LoadingButton
                  variant={"contained"}
                  loading={inProgress}
                  onClick={handleUpdate}
                >
                  Save
                </LoadingButton>
                <Button
                  onClick={() => setOpenDelete(true)}
                  color={"error"}
                  sx={{ mt: 3 }}
                >
                  Delete
                </Button>
              </Stack>
            </Card>
          )}
        </Stack>
      )}
      <ConfirmationDialog
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        title={t("deleteConfirmation.modal.title")}
        message={t("deleteMediaGrid.confirmation.msg").replace(
          "{LABEL}",
          form.label
        )}
        primaryAction={handleDelete}
        secondaryActionTitle={t("buttons.cancel.uppercase").toString()}
        primaryActionTitle={t("buttons.delete").toString()}
        inProgress={loading}
      />
    </Stack>
  );
}

export default MediaGrid;
