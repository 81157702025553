import { Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import IAMPermission from "../../../models/IAMPermission";

export type AppType = {
  name: string;
  icon: JSX.Element;
  path: string;
  disabled: boolean;
  permissions: IAMPermission[];
};

type Props = {
  apps: AppType[];
};
export default function RepoxAppsMenu({ apps }: Props) {
  return (
    <Stack direction={"row"} gap={1} ml={1} mr={1}>
      {apps.map((app, index) => (
        <Button
          color={"primary"}
          target={"_blank"}
          component={Link}
          to={app.path}
          disabled={app.disabled}
          key={index}
        >
          <Stack direction={"column"} gap={1} alignItems={"center"}>
            {app.icon}
            <Typography variant={"caption"}>{app.name}</Typography>
          </Stack>
        </Button>
      ))}
    </Stack>
  );
}
