import Paging from "../../models/Pageable";
import Container from "../../models/Container";
import {API_URL} from "../../utils/config";
import {Datastream} from "../../models/Datastream";
import {useCallback, useEffect, useMemo, useState} from "react";
import {fetchData} from "../../utils/utils";
import useQuery from "../query/useQuery";

const useContainerChildren = (containerUUID?: string, requestOnInit: boolean = true, token?: string) => {

  const [containerResult, setContainerResult] = useState<Paging<Container>>(new Paging<Container>())
  const [datastreamResult, setDatastreamResult] = useState<Paging<Datastream>>(new Paging<Datastream>())
  const [loadingContainers, setLoadingContainers] = useState(false)
  const [loadingDatastreams, setLoadingDatastreams] = useState(false)

  const sortQuery = useQuery().get("sort") ?? "label,asc"

  const containerSortQuery = useMemo(()=>{
    if (sortQuery.includes('filesize') || sortQuery.includes('mimeType')) return 'label,asc'
    return sortQuery
  }, [sortQuery])

  const getContainers = useCallback(async (page: number) => {
    if (!containerUUID) return
    const url = token ? `${API_URL}/containers/${containerUUID}/children?page=${page}&sort=${containerSortQuery}&tk=${token}` : `${API_URL}/containers/${containerUUID}/children?page=${page}&sort=${containerSortQuery}`
    setLoadingContainers(true)
    try {
      const result = await fetchData(url)
      setContainerResult(result)
    } catch (ex: any) {}
    setLoadingContainers(false)

  }, [containerUUID, containerSortQuery, token])

  const getDatastreams = useCallback(async (page: number) => {
    if (!containerUUID) return
    const url = token ? `${API_URL}/containers/${containerUUID}/datastreams?page=${page}&sort=${sortQuery}&tk=${token}` : `${API_URL}/containers/${containerUUID}/datastreams?page=${page}&sort=${sortQuery}`
    setLoadingDatastreams(true)
    try {
      const result = await fetchData(url)
      setDatastreamResult(result)
    } catch (ex: any) {}
    setLoadingDatastreams(false)
  }, [containerUUID, sortQuery, token])

  useEffect(() => {
    if (requestOnInit) {
      getContainers(0)
      getDatastreams(0)
    }
  }, [getContainers, getDatastreams, requestOnInit])

  return {
    containerResult,
    datastreamResult,
    getContainers,
    getDatastreams,
    loadingContainers,
    loadingDatastreams
  }
}

export default useContainerChildren