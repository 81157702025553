import { create } from "zustand";
import { createCommonStore, Entity, useEntityList } from "./core";
import { API_URL } from "../utils/config";
import ContainerType from "../models/ContainerType";

export const useContainerTypeStore = create<Entity<ContainerType>>(
  createCommonStore(`${API_URL}/cm/container_types/all`, "uuid")
);

export const useContainerTypeList = (): [ContainerType[], boolean] => {
  const store = useContainerTypeStore();
  return useEntityList<ContainerType>(store);
};
