import { create } from "zustand";

interface StoreState {
  drawerOpen: boolean;
  toggleLayout: () => void;
}

export const useUIStore = create<StoreState>((set) => ({
  drawerOpen: JSON.parse(localStorage.getItem("isOpen") || "false"),
  toggleLayout: () => {
    set(state => {
      localStorage.setItem("isOpen", JSON.stringify(!state.drawerOpen));
      return {
        drawerOpen: !state.drawerOpen
      }
    })
  }
}));

